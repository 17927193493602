import { Box, CircularProgress } from "@mui/joy";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import Masonry from "react-responsive-masonry";
import { useParams } from "react-router-dom";
import useReq from "../../services";
import { useGamePdfReport, useVerdictPdfReport } from "../../utils/pdfReports";
import Dashboard from "../Dashboard";
import GamesItem from "./GameItem";
import GroundContainer from "./GroundContainer";
import Grounds from "./Grounds";
import useGroundList from "./useGroundList";

// drag and drop utils

// drag and drop utils

const GroupOfGround = () => {
  const { eid } = useParams();
  const [gamesObj, setGamesObj] = useState({});
  const [games, setGames] = useState([]);
  const [grounds, setGrounds] = useState([]);
  const { set: getGrounds, loading: groundLoading } = useReq("get-grounds");
  const { set: getGames, loading: gameLoading } = useReq("get-games");
  const { get: getGroups, loading: groupLoading } = useReq("get-groups-mid");
  const { get: distributeGroups, loading: distributeGroupsLoading } = useReq("distribute-groups");
  const { onGenerateGamesPdf, loading: loadingPdf } = useGamePdfReport();
  const { set: startGamesInGround, loading: startLoading } = useReq("start-games-in-ground");
  const { set: changeGroundOfGroup, loading: changeGroundOfGroupLoading } =
    useReq("change-ground-of-group");
  const { set: removeGamesInGround, loading: removeLoading } = useReq("remove-games-in-ground");
  const { onGenerateVerdictsPdf, loading: generateVerdictPdfLoading } = useVerdictPdfReport();
  const { groundsList, onDragEndGroundList, onInitialGroundList } = useGroundList();

  const handleInitialGroundsList = useCallback(
    async (gds) => {
      if (gds.length && eid) {
        const groups = await getGroups(eid);
        const unknownAssignedGroups = groups.filter((g) => !g.ground_id);
        onInitialGroundList(gds, unknownAssignedGroups);
      }
    },
    [eid, getGroups, onInitialGroundList]
  );

  const getData = useCallback(() => {
    if (eid) {
      getGrounds({ event_id: eid }).then((gData) => {
        handleInitialGroundsList(gData);
        setGrounds(gData);
      });
      getGames({ event_id: eid })
        .then((data) => {
          if (Array.isArray(data)) {
            setGamesObj(
              data.reduce((acc, curr) => {
                return {
                  ...acc,
                  [curr.id]: curr,
                };
              }, {})
            );
            setGames(data);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [getGames, eid, getGrounds, handleInitialGroundsList]);

  useEffect(() => {
    getData();
  }, [getData]);

  const groundWithGames = useMemo(() => {
    if (Object.keys(gamesObj).length && grounds.length) {
      return grounds.map((gd) => ({
        ...gd,
        games_arrange: gd.games_arrange && gd.games_arrange.map((gda) => ({ ...gamesObj[gda] })),
      }));
    }
    return [];
  }, [grounds, gamesObj]);

  const handleGetPdf = (groundIndex) => {
    if (
      groundWithGames[groundIndex] &&
      groundWithGames[groundIndex].games_arrange &&
      groundWithGames[groundIndex].games_arrange.length
    ) {
      onGenerateGamesPdf(groundWithGames[groundIndex].games_arrange);
    }
  };
  const handleGetUnknownGamePdf = (groundIndex) => {
    if (
      groundWithGames[groundIndex] &&
      groundWithGames[groundIndex].games_arrange &&
      groundWithGames[groundIndex].games_arrange.length
    ) {
      const unknownGames = groundWithGames[groundIndex].games_arrange.filter(
        (g) => g.winner === "UNK"
      );
      if (unknownGames.length) {
        onGenerateGamesPdf(unknownGames);
      }
    }
  };

  const handleReload = () => {
    getData();
  };

  const handleStartGame = (index) => {
    startGamesInGround({
      groups_id: groundsList.lists[index].groups.map((g) => g.id),
      ground_id: groundsList.lists[index].id,
    })
      .then(() => handleReload())
      .catch((err) => console.log(err));
  };
  const handleResetGroundGames = (gid) => {
    removeGamesInGround({ ground_id: gid })
      .then(() => handleReload())
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.code === 1045) {
          return toast.error("لطفا وضعیت برنده در بازی های این زمین را به نا مشخص تغییر دهید");
        }
      });
  };

  const handleGetRatePdf = (items) => {
    onGenerateVerdictsPdf(items, eid);
  };

  const gamesGroupedByGroupId = useMemo(() => {
    return games.reduce((acc, curr) => {
      acc[curr.group.id] = acc[curr.group.id] ? [...acc[curr.group.id], curr] : [curr];
      return acc;
    }, []);
  }, [games]);

  const handleDragEnd = (result) => {
    onDragEndGroundList(result, (selectedGroup, destinationGround) => {
      changeGroundOfGroup({ group_id: selectedGroup.id, ground_id: destinationGround })
        .then(() => {
          handleReload();
        })
        .catch((err) => console.log(err));
    });
  };
  const handleDistributeGroupsAmongGrounds = () => {
    distributeGroups([eid])
      .then(() => {
        handleReload();
      })
      .catch(() => {
        console.log();
      });
  };
  return (
    <Dashboard title={"بازی ها"}>
      {(loadingPdf ||
        gameLoading ||
        groundLoading ||
        startLoading ||
        groupLoading ||
        generateVerdictPdfLoading ||
        distributeGroupsLoading ||
        changeGroundOfGroupLoading ||
        removeLoading) && (
        <Box
          sx={{
            zIndex: 2,
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#ffffffaa",
          }}
        >
          <CircularProgress size={"lg"} />
        </Box>
      )}
      <Box display={"flex"} gap={0} width={"100%"} height={"100%"}>
        <Box flex={1} position={"relative"}>
          <Box position={"absolute"} p={2} sx={{ inset: 0, overflowY: "auto", bgcolor: "red" }}>
            <Masonry columnsCount={2} gutter="15px">
              {groundWithGames.map((ground, index) => (
                <GroundContainer
                  key={index}
                  ground={ground}
                  loadingPdf={loadingPdf}
                  onGetUnknownGamePdf={() => handleGetUnknownGamePdf(index)}
                  onGetPdf={() => handleGetPdf(index)}
                >
                  {ground.games_arrange &&
                    ground.games_arrange.map((g, index) => {
                      return <GamesItem key={index} game={g} />;
                    })}
                </GroundContainer>
              ))}
            </Masonry>
          </Box>
        </Box>

        <Grounds
          onDistributeGroups={handleDistributeGroupsAmongGrounds}
          onStartGame={(gi) => handleStartGame(gi)}
          allGamesByGroup={gamesGroupedByGroupId}
          onGetRatePdf={handleGetRatePdf}
          groundsList={groundsList}
          onDragEnd={handleDragEnd}
          onReset={handleResetGroundGames}
        />
      </Box>
    </Dashboard>
  );
};

// Put the things into the DOM!
export default GroupOfGround;
