import { Box, CircularProgress, Divider, IconButton, Sheet, Typography } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
// import { Masonry } from "@mui/lab";

import { LuAlertCircle, LuAlignJustify, LuCheck, LuMoreVertical, LuUser } from "react-icons/lu";
import Masonry from "react-responsive-masonry";
import { useParams } from "react-router-dom";
import useReq from "../services";
import Dashboard from "./Dashboard";
import Layout from "./Layout";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return [result, removed];
};

const GAME_MOLDS = [2, 4, 8, 16, 32, 64, 128, 256, 512, 1024];

const PLayerCountAlarm = ({ count }) => {
  if (count === 0) return null;
  const isInGameMold = GAME_MOLDS.findIndex((m) => m === count) !== -1;

  if (isInGameMold) {
    return (
      <Box
        sx={{
          alignItems: "center",
          gap: 1,
          display: "flex",
          flexDirection: "row-reverse",
          border: "1px solid",
          borderColor: "success.100",
          borderRadius: "50px",
          pr: 1,
        }}
      >
        <Box
          sx={{
            bgcolor: "success.100",
            color: "success.500",
            height: 20,
            width: 20,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LuCheck size={12} />
        </Box>
        <Typography pt={0.2} color="success" fontSize={12}>
          {count}
        </Typography>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          alignItems: "center",
          gap: 1,
          display: "flex",
          flexDirection: "row-reverse",
          border: "1px solid",
          borderColor: "danger.100",
          borderRadius: "50px",
          pr: 1,
        }}
      >
        <Box
          sx={{
            bgcolor: "danger.100",
            color: "danger.500",
            height: 20,
            width: 20,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LuAlertCircle size={12} />
        </Box>
        <Typography pt={0.2} color="danger" fontSize={12}>
          {count}
        </Typography>
      </Box>
    );
  }
};

const GroupPlayers = () => {
  const { eid } = useParams();
  const { get: getGroups, loading: groupsLoading } = useReq("get-groups-with-players");
  const { set: changePlayerGroup, loading: changePlayerGroupLoading } =
    useReq("change-player-group");
  const [movementState, setMovementState] = useState(null);

  const [state, setState] = useState({
    lists: [],
  });

  const getList = (id) => state.lists[id]["players"];

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    if (source.droppableId === destination.droppableId) {
      const items = reorder(getList(source.droppableId), source.index, destination.index);
      let newList = state.lists;
      newList[source.droppableId]["players"] = items;
      setState({ lists: [...newList] });
    } else {
      const [result, item] = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );

      setMovementState({ destination, source });
      changePlayerGroup({
        player_id: item.id,
        destination_group_id: state.lists[destination.droppableId].id,
      })
        .then(() => {})
        .catch(() => {
          alert("یک مشکل در برقراری با سرور رخ داده لطفا بررسی کنید");
        });
      setState((prevState) => {
        let newList = prevState.lists;
        newList[source.droppableId]["players"] = result[source.droppableId];
        newList[destination.droppableId]["players"] = result[destination.droppableId];
        return { ...prevState, list: newList };
      });
    }
  };

  useEffect(() => {
    getGroups(eid).then((data) =>
      setState((prev) => {
        return {
          ...prev,
          lists:
            data && Array.isArray(data)
              ? data.map((g) => ({
                  ...g,
                  players: g.players.map((p) => ({
                    ...p,
                    cardId: `item-${p.id}`,
                  })),
                }))
              : [],
        };
      })
    );
  }, [eid, getGroups]);

  return (
    <Dashboard title={"گروه ها"}>
      <Layout loading={groupsLoading}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Masonry columns={4} gutter="15px">
            {state.lists.map((gp, index) => (
              <Droppable key={index} droppableId={`${index}`}>
                {(provided, snapshot) => (
                  <Sheet
                    ref={provided.innerRef}
                    variant="outlined"
                    sx={(theme) => ({
                      width: "100%",
                      transition: "200ms",
                      borderRadius: theme.radius.md,
                      boxShadow: theme.shadow.sm,
                      background: "var(--joy-palette-background-surface)",
                      borderColor: snapshot.isDraggingOver
                        ? "var(--joy-palette-primary-softColor)"
                        : "var(--joy-palette-neutral-outlinedBorder)",
                      p: 1.5,
                      gap: 2,
                      position: "relative",
                    })}
                  >
                    {gp.started ? (
                      <div
                        style={{
                          inset: 0,
                          background: "#ffffff99",
                          borderRadius: "1rem",
                          position: "absolute",
                          zIndex: 1,
                        }}
                      ></div>
                    ) : null}
                    <Box>
                      <Box display={"flex"} sx={{ pb: 1, px: 1 }} alignItems={"center"}>
                        <Box display={"flex"} flex={1} alignItems={"center"} gap={2.3}>
                          <Box sx={{ display: "flex", gap: 1 }}>
                            <Typography fontSize={14}>{gp.age && gp.age.title}</Typography>
                            <Typography fontSize={14}>
                              وزن {gp.weight && gp.weight.title}
                            </Typography>
                            <Typography fontSize={14}>کمربند {gp.belt && gp.belt.title}</Typography>
                          </Box>

                          <PLayerCountAlarm count={gp.players.length} />
                          {changePlayerGroupLoading &&
                            movementState &&
                            (parseInt(movementState.source.droppableId) === index ||
                              parseInt(movementState.destination.droppableId) === index) && (
                              <CircularProgress size="sm" />
                            )}
                        </Box>

                        <IconButton variant={"outlined"} size="sm" color={"primary"}>
                          <LuMoreVertical size={18} />
                        </IconButton>
                      </Box>
                      <Divider />
                    </Box>
                    {gp.players &&
                      gp.players.map((pItem, index) => (
                        <Draggable key={pItem.cardId} draggableId={pItem.cardId} index={index}>
                          {(provided, snapshot) => (
                            <Sheet
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              variant="outlined"
                              sx={(theme) => ({
                                p: 1,
                                mt: 1,
                                boxShadow: snapshot.isDragging ? theme.shadow.sm : "none",
                                borderRadius: theme.radius.sm,
                                display: "flex",
                                justifyContent: "start",
                                gap: 1,
                                alignItems: "center",
                              })}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  ml: 0.5,
                                  mr: 1,
                                  color: "text.tertiary",
                                }}
                              >
                                <LuAlignJustify />
                              </Box>
                              <Box
                                sx={{
                                  p: 1,
                                  bgcolor: "primary.50",
                                  borderRadius: "50%",
                                }}
                              >
                                <Box
                                  sx={{
                                    width: 32,
                                    height: 32,
                                    borderRadius: "50%",
                                    bgcolor: "primary.100",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "primary.500",
                                  }}
                                >
                                  <LuUser size={20} />
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "start",
                                }}
                              >
                                <Typography>{pItem.name}</Typography>
                                <Box sx={{ display: "flex", gap: 1 }}>
                                  <Typography level="body3">
                                    {pItem.age && pItem.age.title}
                                  </Typography>
                                  <Typography level="body3">
                                    {pItem.weight && pItem.weight.title}
                                  </Typography>
                                  <Typography level="body3">
                                    {pItem.belt && pItem.belt.title}
                                  </Typography>
                                </Box>
                              </Box>
                              {/* {item.content} */}
                            </Sheet>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </Sheet>
                )}
              </Droppable>
            ))}
          </Masonry>
        </DragDropContext>
      </Layout>
    </Dashboard>
  );
};

export default GroupPlayers;
