import { Box, Typography } from "@mui/joy";
import React from "react";
import { LuImage } from "react-icons/lu";

const ScreenshotButton = ({ onCapture }) => {
  return (
    <Box
      position={"absolute"}
      bottom={30}
      left={375}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      px={0.8}
      py={0.5}
      gap={0.6}
      sx={{
        bgcolor: "background.surface",
        borderRadius: "sm",
        boxShadow: "sm",
        cursor: "pointer",
      }}
      onClick={onCapture}
    >
      <Typography fontSize={"0.8em"}>چاپ تصویر</Typography>
      <Box
        sx={{
          p: 0.5,
          borderRadius: "sm",
          border: "1px solid",
          borderColor: "neutral.300",
          color: "neutral.600",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ":hover": {
            bgcolor: "primary.100",
          },
        }}
      >
        <LuImage />
      </Box>
    </Box>
  );
};

export default ScreenshotButton;
