import { IconButton } from "@mui/joy";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import Link from "@mui/joy/Link";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import fileDownload from "js-file-download";
import * as React from "react";
import { LuArrowDown, LuPrinter } from "react-icons/lu";
import useReq from "../../services";
import Dashboard from "../Dashboard";
import Layout from "./../Layout";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function MembersFilter() {
  const { state: games, set: getPlayers, loading } = useReq("get-players");
  const { state: events } = useReq("get-events");
  const { set: getPdf, loading: loadingPdf } = useReq("create-table-pdf");
  // const { state: ages } = useReq("get-ages");
  // const { state: weights } = useReq("get-weights");
  const { state: coaches } = useReq("get-all-coaches");
  const [order, setOrder] = React.useState("desc");
  const handleClick = (id) => {};
  const [filters, setFilters] = React.useState({});

  const handleChangeFilter = (key, value) => {
    if (value && value !== "*") {
      setFilters({
        ...filters,
        [key]: value,
      });
    } else if (value === "*") {
      const newFilters = { ...filters };
      delete newFilters[key];
      setFilters(newFilters);
    }
  };

  React.useEffect(() => {
    getPlayers({ ...filters });
  }, [getPlayers, filters]);

  const handleGetPdf = () => {
    getPdf({
      headers: ["نام و نام خانوادگی", "سن", "وزن", "کمربند", "مربی", "باشگاه"].reverse(),
      items: [
        ...games.map((p) => {
          return [
            p.name,
            p.age ? p.age.title : "",
            p.weight ? p.weight.title : "",
            p.belt ? p.belt.title : "",
            p.coach ? p.coach.name : "",
            p.coach ? p.coach.club_title : "",
          ].reverse();
        }),
      ],
    })
      .then((data) =>
        fileDownload(new Blob([data], { type: "application/pdf;charset=UTF-8" }), "file.pdf")
      )
      .catch((err) => console.log(err));
  };
  return (
    <Dashboard title={"فیلتر مبارزان"}>
      <Layout
        loading={loading || loadingPdf}
        display={"flex"}
        flexDirection={"column"}
        height={"100%"}
      >
        <Box
          className="SearchAndFilters-tabletUp"
          sx={{
            borderRadius: "sm",
            pb: 2,
            display: {
              xs: "none",
              sm: "flex",
            },
            alignItems: "center",
            flexWrap: "wrap",
            gap: 1.5,
            "& > *": {
              minWidth: {
                xs: "120px",
                md: "160px",
              },
            },
          }}
        >
          <Box sx={{ flex: 1, display: "flex", alignItems: "center", gap: 2 }}>
            <FormControl size="sm">
              <Select
                defaultValue={"*"}
                sx={{ width: 200 }}
                onChange={(_e, v) => handleChangeFilter("event", v)}
                placeholder="رویداد"
              >
                <Option value={"*"}>همه رویداد ها</Option>
                {events.map((e, i) => (
                  <Option key={i} value={e.id}>
                    {e.title}
                  </Option>
                ))}
              </Select>
            </FormControl>

            <FormControl size="sm">
              <Select
                defaultValue={"*"}
                sx={{ width: 200 }}
                onChange={(_e, v) => handleChangeFilter("coach", v)}
                placeholder="مربی ها"
              >
                <Option value={"*"}>همه مربی ها </Option>
                {coaches.map((c, i) => (
                  <Option key={i} value={c.id}>
                    {c.name}
                  </Option>
                ))}
              </Select>
            </FormControl>
          </Box>

          <FormControl size="sm"></FormControl>

          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <IconButton variant="plain" size="sm" onClick={handleGetPdf}>
              <LuPrinter />
            </IconButton>

            <Button size="sm" sx={{ flex: 1 }} onClick={() => {}}>
              فیلتر
            </Button>
          </Box>
        </Box>
        <Sheet
          className="OrderTableContainer"
          variant="outlined"
          sx={{
            width: "100%",
            borderRadius: "md",
            flex: 1,
            overflow: "auto",
            minHeight: 0,
          }}
        >
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            hoverRow
            sx={{
              "--TableCell-headBackground": (theme) => theme.vars.palette.background.level1,
              "--Table-headerUnderlineThickness": "1px",
              "--TableRow-hoverBackground": (theme) => theme.vars.palette.background.level1,
            }}
          >
            <thead>
              <tr>
                <th style={{ width: 30, padding: "12px 18px", textAlign: "right" }}>
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    onClick={() => setOrder(order === "asc" ? "desc" : "asc")}
                    fontWeight="lg"
                    endDecorator={<LuArrowDown />}
                    sx={{
                      "& svg": {
                        transition: "0.2s",
                        transform: order === "desc" ? "rotate(0deg)" : "rotate(180deg)",
                      },
                    }}
                  >
                    شناسه
                  </Link>
                </th>
                <th style={{ width: 100, padding: 12, textAlign: "right" }}>نام و نام خانوادگی</th>
                <th style={{ width: 50, padding: 12, textAlign: "right" }}>سن</th>
                <th style={{ width: 50, padding: 12, textAlign: "right" }}>وزن</th>
                <th style={{ width: 50, padding: 12, textAlign: "right" }}>کمربند</th>
                <th style={{ width: 90, padding: 12, textAlign: "right" }}>مربی</th>
                <th style={{ width: 90, padding: 12, textAlign: "right" }}>باشگاه</th>
              </tr>
            </thead>
            <tbody>
              {games &&
                stableSort(games, getComparator(order, "id")).map((row) => (
                  <tr
                    key={row.id}
                    onClick={() => handleClick(row.id)}
                    style={{ cursor: "pointer" }}
                  >
                    <td style={{ textAlign: "right", padding: "0 15px" }}>
                      <Typography fontWeight="md">{row.id}</Typography>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <Typography fontWeight="md">{row.name}</Typography>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <Typography fontWeight="md">{row.age && row.age.title}</Typography>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <Typography fontWeight="md">{row.weight && row.weight.title}</Typography>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <Typography fontWeight="md">{row.belt && row.belt.title}</Typography>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <Typography fontWeight="md">{row.coach && row.coach.name}</Typography>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <Typography fontWeight="md">{row.coach && row.coach.club_title}</Typography>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Sheet>
      </Layout>
    </Dashboard>
  );
}
