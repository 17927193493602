/* eslint-disable jsx-a11y/anchor-is-valid */
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import GlobalStyles from "@mui/joy/GlobalStyles";
import IconButton from "@mui/joy/IconButton";
import Input from "@mui/joy/Input";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import { styled } from "@mui/joy/styles";
import * as React from "react";
import { LuFilter, LuLogOut, LuSettings, LuUsers } from "react-icons/lu";
import { MdOutlineEmojiEvents, MdOutlineSportsMartialArts } from "react-icons/md";
import { RiFolderAddLine, RiSearchLine } from "react-icons/ri";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { closeSidebar } from "../utils/utils";
import ColorSchemeToggle from "./ColorSchemeToggle";
const Dropdown = styled("i")(({ theme }) => ({
  color: theme.vars.palette.text.tertiary,
}));

const IS_SELECTED_LIST_ITEM = { selected: true, variant: "soft", color: "primary" };

export default function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };
  const menuItems = React.useMemo(() => {
    const items = [
      { name: "رویداد ها", icon: <MdOutlineEmojiEvents size={22} />, route: "/dashboard/" },
      {
        name: "ایجاد رویداد",
        icon: <RiFolderAddLine size={22} />,
        route: "/dashboard/create-event",
      },
      { name: "فیلتر بازی", icon: <LuFilter size={20} />, route: "/dashboard/game-filter" },
      { name: "فیلتر مبارزان", icon: <LuFilter size={20} />, route: "/dashboard/member-filter" },
      { name: "قوانین بازی", icon: <LuUsers size={20} />, route: "/dashboard/game-roles" },
      { name: "تنظیمات", icon: <LuSettings size={20} />, route: "/dashboard/settings" },
    ];
    return items.map((i) => {
      if (i.route === location.pathname) {
        return { ...i, selected: true };
      }
      return i;
    });
  }, [location]);

  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: {
          xs: "fixed",
          md: "sticky",
        },
        transform: {
          xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))",
          md: "none",
        },
        transition: "transform 0.4s, width 0.4s",
        zIndex: 10000,
        height: "100dvh",
        boxShadow: "0px 1px 2px #00000022",
        width: "var(--Sidebar-width)",
        top: 0,
        p: 1.5,
        py: 3,
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        borderRight: "1px solid",
        borderColor: "divider",
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ":root": {
            "--Sidebar-width": "224px",
            [theme.breakpoints.up("lg")]: {
              "--Sidebar-width": "256px",
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: "fixed",
          zIndex: 9998,
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          bgcolor: "background.body",
          opacity: "calc(var(--SideNavigation-slideIn, 0) - 0.2)",
          transition: "opacity 0.4s",
          transform: {
            xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))",
            lg: "translateX(-100%)",
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <MdOutlineSportsMartialArts color={"var(--joy-palette-primary-softColor)"} size={24} />{" "}
        {/** TODO: refactor this */}
        <Typography fontWeight="xl">تکواندوکار</Typography>
        <ColorSchemeToggle sx={{ mr: "auto" }} />
      </Box>
      <Input startDecorator={<RiSearchLine size={22} />} placeholder="Search" />
      <Box
        sx={{
          minHeight: 0,
          overflow: "hidden auto",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <List
          sx={{
            "--ListItem-radius": "8px",
            "--List-gap": "4px",
            "--List-nestedInsetStart": "40px",
          }}
        >
          {menuItems.map((mi, i) => (
            <Link key={i} to={mi.route} style={{ textDecoration: "none" }}>
              <ListItem>
                <ListItemButton {...(mi.selected ? IS_SELECTED_LIST_ITEM : {})}>
                  <ListItemDecorator>{mi.icon}</ListItemDecorator>
                  <ListItemContent>{mi.name}</ListItemContent>
                  <Dropdown data-feather="chevron-down" />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>
      </Box>
      <Divider />
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Avatar variant="outlined" src="/static/images/avatar/3.jpg" />
        <Box sx={{ minWidth: 0, flex: 1 }}>
          <Typography fontSize="sm" fontWeight="lg">
            کاربر ادمین
          </Typography>
          <Typography level="body-sm" color="success">
            online
          </Typography>
        </Box>
        <IconButton onClick={handleLogout} variant="plain" color="neutral">
          <LuLogOut />
        </IconButton>
      </Box>
    </Sheet>
  );
}
