import React, { useEffect, useState } from "react";

import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Typography from "@mui/joy/Typography";
import { Input, Option, Select } from "@mui/joy";

export class FormItem {
  constructor(key, type, defaultProps) {
    this.type = type;
    this.key = key;
    this.defaultProps = defaultProps;
  }
}

export const FORM_TYPES = {
  string: 1,
  number: 2,
  select: 3,
  selectBig: 4,
  color: 5,
};

const FORM_TYPE_DEFAULT_VALUES = {
  [FORM_TYPES.string]: "",
  [FORM_TYPES.color]: "",
};

function generateExpected(form) {
  return form.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.key]: FORM_TYPE_DEFAULT_VALUES[curr.type],
    };
  }, {});
}

const CrudModalContainer = ({ anchor, onClose, width, form }) => {
  const [state, setState] = useState({});
  const handleChangeState = (key, value) => {
    setState({ ...state, [key]: value });
  };
  const handleCreate = () => {
    form.onCreate({ ...generateExpected(form.items), ...state });
  };
  const handleEdit = () => {
    form.onEdit({ ...generateExpected(form.items), ...state });
  };
  const handleDelete = () => {
    form.onDelete(state);
  };
  const handleClose = () => onClose();
  useEffect(() => {
    setState({ ...generateExpected(form.items), ...anchor.data });
  }, [anchor, form]);
  return (
    <Modal sx={{ zIndex: 10000, direction: "rtl" }} open={true} onClose={handleClose}>
      <ModalDialog
        aria-labelledby="nested-modal-title"
        aria-describedby="nested-modal-description"
        sx={() => ({
          maxWidth: width || "unset",
        })}
      >
        <Typography id="nested-modal-title" level="h2">
          {anchor.data ? form.editTitle : form.createTitle}
        </Typography>
        <Box sx={{ gap: 1, display: "flex", position: "relative", flexDirection: "column" }}>
          {form.items &&
            form.items.map((f, i) => {
              if (f.type === FORM_TYPES.string || f.type === FORM_TYPES.color) {
                return (
                  <Input
                    sx={f.defaultProps && f.defaultProps.sx ? { ...f.defaultProps.sx } : {}}
                    value={state[f.key]}
                    key={i}
                    placeholder={f.defaultProps && f.defaultProps.placeholder}
                    onChange={(e) => handleChangeState(f.key, e.target.value)}
                  />
                );
              }
              if (f.type === FORM_TYPES.select) {
                return (
                  <Select
                    sx={{ flex: 1 }}
                    size="sm"
                    name={"age"}
                    onChange={(_, v) => handleChangeState(f.key, v)}
                    placeholder={f.defaultProps && f.defaultProps.placeholder}
                    value={state[f.key]}
                    slotProps={{
                      listbox: { sx: { zIndex: 100000000 } },
                    }}
                  >
                    {f.defaultProps &&
                      f.defaultProps.items.map((a) => <Option value={a.key}>{a.title}</Option>)}
                  </Select>
                );
              }
              return null;
            })}
        </Box>
        <Box
          sx={{
            mt: 1,
            display: "flex",
            gap: 1,
            flexDirection: "row-reverse",
          }}
        >
          {!anchor.data ? (
            <Button variant="solid" color="primary" onClick={handleCreate}>
              ذخیره
            </Button>
          ) : (
            <React.Fragment>
              <Button variant="solid" color="primary" onClick={handleEdit}>
                ویرایش
              </Button>
              <Button variant="solid" color="danger" onClick={handleDelete}>
                حذف
              </Button>
            </React.Fragment>
          )}
          <Button variant="outlined" color="neutral" onClick={handleClose}>
            لغو
          </Button>
        </Box>
      </ModalDialog>
    </Modal>
  );
};

const CrudModal = (props) => {
  if (props.anchor.status) {
    return <CrudModalContainer {...props} />;
  }
  return null;
};

export default CrudModal;
