import { Box } from "@mui/joy";
import React from "react";

const ImageSide = () => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "50%",
        position: "relative",
        display: { xs: "none", md: "block" },
      }}
    >
      <img
        style={{
          position: "absolute",
          objectFit: "cover",
          inset: 0,
          height: "100%",
          width: "100%",
        }}
        src="/images/background.jpg"
        alt="bg"
      />
      <Box
        component={"a"}
        href="https://devlic.com"
        sx={{
          px: 1,
          py: 0.5,
          bgcolor: "#eeeeee55",
          borderRadius: "lg",
          position: "absolute",
          textDecoration: "none",
          color: "white",
          bottom: 10,
          left: "50%",
          backdropFilter: "blur(4px)",
          transform: "translate(-50%)",
          fontSize: "xs",
        }}
      >
        Made by Devlic.com
      </Box>
    </Box>
  );
};

export default ImageSide;
