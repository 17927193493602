const PREFIX = `https://admin.tkdman.top/api/`;

const ENDPOINTS = [
  {
    id: "get-players",
    url: `${PREFIX}players/`,
    method: "POST",
    default: [],
    ignoreRunInMount: true,
  },
  {
    id: "get-group-with-players",
    url: (eid, gid) => `${PREFIX}get-groups-with-players/${eid}/${gid}`,
    method: "GET",
    default: [],
    ignoreRunInMount: true,
  },
  {
    id: "get-groups-with-players",
    url: (id) => `${PREFIX}get-groups-with-players/${id}/`,
    method: "GET",
    default: [],
    ignoreRunInMount: true,
  },
  {
    id: "get-events",
    url: `${PREFIX}events/`,
    method: "GET",
    isAuth: true,
    default: [],
  },
  {
    id: "get-available-events",
    url: `${PREFIX}available-events/`,
    method: "GET",
    isAuth: true,
    default: [],
  },
  {
    id: "get-cities",
    url: `${PREFIX}get-cities/`,
    method: "GET",
    default: [],
  },
  {
    id: "get-states",
    url: `${PREFIX}get-states/`,
    method: "GET",
    default: [],
  },
  {
    id: "get-clubs",
    url: `${PREFIX}get-clubs/`,
    method: "GET",
    default: [],
  },
  {
    id: "create-club",
    url: `${PREFIX}club/`,
    method: "POST",
    default: [],
    ignoreRunInMount: true,
  },
  {
    id: "create-player",
    url: `${PREFIX}player/`,
    method: "POST",
    default: [],
    ignoreRunInMount: true,
  },
  {
    id: "edit-player",
    url: (id) => `${PREFIX}player/${id}/`,
    method: "PUT",
    default: [],
    ignoreRunInMount: true,
  },
  {
    id: "remove-player",
    url: (id) => `${PREFIX}player/${id}/`,
    method: "DELETE",
    default: [],
    ignoreRunInMount: true,
  },
  {
    id: "get-belts",
    url: `${PREFIX}belts/`,
    method: "GET",
    default: [],
  },
  {
    id: "create-event",
    url: `${PREFIX}event/`,
    method: "POST",
    ignoreRunInMount: true,
  },
  {
    id: "get-event-by-id",
    url: (id) => `${PREFIX}event/${id}/`,
    method: "GET",
    ignoreRunInMount: true,
    defaultLoadingState: true,
  },
  {
    id: "update-event",
    url: (id) => `${PREFIX}event/${id}`,
    method: "PUT",
    ignoreRunInMount: true,
  },
  {
    id: "remove-coach",
    url: (id) => `${PREFIX}coach/${id}/`,
    method: "DELETE",
    ignoreRunInMount: true,
  },
  {
    id: "create-coach",
    url: `${PREFIX}coach/`,
    method: "POST",
    ignoreRunInMount: true,
  },
  {
    id: "create-ground",
    url: `${PREFIX}ground/`,
    method: "POST",
    ignoreRunInMount: true,
  },
  {
    id: "remove-ground",
    url: (id) => `${PREFIX}ground/${id}/`,
    method: "DELETE",
    ignoreRunInMount: true,
  },
  {
    id: "remove-event",
    url: (id) => `${PREFIX}event/${id}/`,
    method: "DELETE",
    ignoreRunInMount: true,
  },
  {
    id: "get-weights",
    url: `${PREFIX}weights/`,
    method: "GET",
    default: [],
  },
  {
    id: "get-ages",
    url: `${PREFIX}ages/`,
    method: "GET",
    default: [],
  },
  {
    id: "get-belts",
    url: `${PREFIX}belts/`,
    method: "GET",
    default: [],
  },
  {
    id: "get-coaches",
    url: `${PREFIX}coaches/`,
    method: "POST",
    default: [],
    ignoreRunInMount: true,
  },
  {
    id: "get-all-coaches",
    url: `${PREFIX}coaches/`,
    method: "POST",
    default: [],
    data: {},
  },
  {
    id: "create-update-players",
    url: `${PREFIX}create-update-players/`,
    method: "POST",
    ignoreRunInMount: true,
  },
  {
    id: "get-groups-mid",
    url: (id) => `${PREFIX}get-groups-mid/${id}/`,
    method: "GET",
    ignoreRunInMount: true,
  },
  {
    id: "change-player-group",
    url: `${PREFIX}move-payer-between-groups/`,
    method: "POST",
    ignoreRunInMount: true,
  },
  {
    id: "get-grounds",
    url: `${PREFIX}grounds/`,
    method: "POST",
    default: [],
    ignoreRunInMount: true,
  },
  {
    id: "start-games-in-ground",
    url: `${PREFIX}start-games-in-ground/`,
    method: "POST",
    default: [],
    ignoreRunInMount: true,
  },
  {
    id: "change-ground-of-group",
    url: `${PREFIX}change-ground-of-group/`,
    method: "POST",
    default: [],
    ignoreRunInMount: true,
  },
  {
    id: "remove-games-in-ground",
    url: `${PREFIX}remove-games-in-ground/`,
    method: "POST",
    default: [],
    ignoreRunInMount: true,
  },
  {
    id: "get-games",
    url: `${PREFIX}get-games/`,
    method: "POST",
    default: [],
    ignoreRunInMount: true,
  },
  {
    id: "get-games-by-group",
    url: (id) => `${PREFIX}get-games-by-group/${id}`,
    method: "GET",
    default: [],
    ignoreRunInMount: true,
  },
  {
    id: "get-games-by-group-expand",
    url: (id) => `${PREFIX}get-games-by-group-expand/${id}`,
    method: "GET",
    default: [],
    ignoreRunInMount: true,
  },
  {
    id: "distribute-groups",
    url: (eid) => `${PREFIX}distribute-groups-among-grounds/${eid}/`,
    method: "POST",
    default: [],
    ignoreRunInMount: true,
  },
  {
    id: "update-game",
    url: `${PREFIX}update-game/`,
    method: "POST",
    default: [],
    ignoreRunInMount: true,
  },
  {
    id: "get-token",
    url: `${PREFIX}token/`,
    method: "POST",
    default: [],
    ignoreRunInMount: true,
  },
  {
    id: "generate-game-pdf",
    url: `${PREFIX}generate-game-pdf/`,
    method: "POST",
    default: [],
    ignoreRunInMount: true,
    isBuffer: true,
  },
  {
    id: "generate-rate-pdf",
    url: `${PREFIX}generate-rate-pdf/`,
    method: "POST",
    default: [],
    ignoreRunInMount: true,
    isBuffer: true,
  },
  {
    id: "generate-game-pdf",
    url: `${PREFIX}generate-game-pdf/`,
    method: "POST",
    default: [],
    ignoreRunInMount: true,
    isBuffer: true,
  },
  {
    id: "create-table-pdf",
    url: `${PREFIX}create-table-pdf/`,
    method: "POST",
    default: [],
    ignoreRunInMount: true,
    isBuffer: true,
  },

  {
    id: "create-belt",
    url: `${PREFIX}belt/`,
    method: "POST",
    default: [],
    ignoreRunInMount: true,
  },
  {
    id: "edit-belt",
    url: (id) => `${PREFIX}belt/${id}/`,
    method: "PUT",
    ignoreRunInMount: true,
  },
  {
    id: "delete-belt",
    url: (id) => `${PREFIX}belt/${id}/`,
    method: "DELETE",
    default: [],
    ignoreRunInMount: true,
  },

  {
    id: "create-age",
    url: `${PREFIX}age/`,
    method: "POST",
    default: [],
    ignoreRunInMount: true,
  },
  {
    id: "edit-age",
    url: (id) => `${PREFIX}age/${id}/`,
    method: "PUT",
    ignoreRunInMount: true,
  },
  {
    id: "delete-age",
    url: (id) => `${PREFIX}age/${id}/`,
    method: "DELETE",
    default: [],
    ignoreRunInMount: true,
  },

  {
    id: "create-weight",
    url: `${PREFIX}weight/`,
    method: "POST",
    default: [],
    ignoreRunInMount: true,
  },
  {
    id: "edit-weight",
    url: (id) => `${PREFIX}weight/${id}/`,
    method: "PUT",
    ignoreRunInMount: true,
  },
  {
    id: "delete-weight",
    url: (id) => `${PREFIX}weight/${id}/`,
    method: "DELETE",
    default: [],
    ignoreRunInMount: true,
  },
];

export { ENDPOINTS };
