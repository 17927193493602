import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalDialog,
  Stack,
  Typography,
} from "@mui/joy";
import React from "react";
import useReq from "../../services";

const CreateClubModal = ({ open, onClose, onCreate }) => {
  const { set, loading } = useReq("create-club", false);
  const [state, setState] = React.useState("");
  return (
    <Modal open={open} onClose={onClose} sx={{ direction: "rtl", zIndex: 1000000000 }}>
      <ModalDialog sx={{ maxWidth: 500 }}>
        <Typography id="basic-modal-dialog-title" component="h2">
          ایجاد باشگاه جدید
        </Typography>

        <form
          onSubmit={(event) => {
            event.preventDefault();
            set({ title: state }).then(() => {
              onCreate();
              setState("");
            });
          }}
        >
          <Stack spacing={2} pt={2}>
            <FormControl>
              <FormLabel>نام باشگاه</FormLabel>
              <Input autoFocus required value={state} onChange={(e) => setState(e.target.value)} />
            </FormControl>

            <Button type="submit" loading={loading}>
              تایید
            </Button>
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  );
};

export default CreateClubModal;
