import {
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalDialog,
  Option,
  Select,
  Stack,
  Typography,
} from "@mui/joy";
import React from "react";
import { LuPlus } from "react-icons/lu";
import useReq from "../../services";
import CreateClubModal from "./CreateClubModal";

const INITIAL_STATE = {
  name: "",
  clubId: "",
  clubTitle: "",
};
const CreateCoachModal = ({ open, onClose, onAdd, loading = false }) => {
  const { state: clubs, get: getClubs } = useReq("get-clubs");
  const [state, setState] = React.useState(INITIAL_STATE);
  const [openCreateClubModal, setOpenCreateClubModal] = React.useState(false);
  const handleCloseCreateClubModal = () => setOpenCreateClubModal(false);
  const handleOpenCreateClubModal = () => setOpenCreateClubModal(true);
  const handleCloseClubCreateModalAndRefresh = () => {
    handleCloseCreateClubModal();
    getClubs();
  };
  return (
    <Modal open={open} onClose={onClose} sx={{ direction: "rtl", zIndex: 100000 }}>
      <ModalDialog sx={{ maxWidth: 500 }}>
        <Typography id="basic-modal-dialog-title" component="h2">
          ایجاد مربی جدید
        </Typography>
        <Typography id="basic-modal-dialog-description" textColor="text.tertiary">
          ایجاد مربی جدید و انتخاب باشگاه مورد نظر
        </Typography>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            onAdd(state);
            setState(INITIAL_STATE);
          }}
        >
          <Stack spacing={2}>
            <FormControl>
              <FormLabel>نام</FormLabel>
              <Input
                autoFocus
                required
                value={state.name}
                onChange={(e) => setState({ ...state, name: e.target.value })}
              />
            </FormControl>
            <FormControl>
              <FormLabel>باشگاه</FormLabel>
              <Select
                sx={{ flex: 1 }}
                size="sm"
                endDecorator={
                  <IconButton
                    sx={{ p: 0.5 }}
                    variant="soft"
                    size={"small"}
                    onClick={handleOpenCreateClubModal}
                  >
                    <LuPlus />
                  </IconButton>
                }
                placeholder="انتخاب باشگاه"
                slotProps={{
                  button: { sx: { whiteSpace: "nowrap" } },
                  listbox: { sx: { zIndex: 100000000000 } },
                }}
              >
                {clubs.map((c) => (
                  <Option
                    value={c.id}
                    onClick={() => setState({ ...state, clubId: c.id, clubTitle: c.title })}
                  >
                    {c.title}
                  </Option>
                ))}
              </Select>
            </FormControl>
            <Button type="submit" loading={loading}>
              تایید
            </Button>
          </Stack>
        </form>
        <CreateClubModal
          open={openCreateClubModal}
          onClose={handleCloseCreateClubModal}
          onCreate={handleCloseClubCreateModalAndRefresh}
        />
      </ModalDialog>
    </Modal>
  );
};

export default CreateCoachModal;
