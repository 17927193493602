import { Card, Checkbox, IconButton, Radio } from "@mui/joy";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Textarea from "@mui/joy/Textarea";
import Typography from "@mui/joy/Typography";
import * as React from "react";
import { LuTrash2, LuUser } from "react-icons/lu";
import { TbPlaystationSquare } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import useReq from "../services";
import Layout from "./Layout";
import CreateCoachModal from "./Modals/CreateCoachModal";
import CreateGroundModal from "./Modals/CreateGroundModal";
import Dashboard from "./Dashboard";

export default function CreateEvent() {
  const { state: cities } = useReq("get-cities");
  const { state: states } = useReq("get-states");
  const { state: belts } = useReq("get-belts");
  const { set: createEvent, loading } = useReq("create-event");
  const [openCreateCoachModal, setOpenCreateCoachModal] = React.useState(false);
  const navigate = useNavigate();
  const [openCreateGroundModal, setOpenCreateGroundModal] = React.useState(false);
  const [createState, setCreateState] = React.useState({
    description: "",
    title: "",
    sex: "MAN",
    city: null,
    state: null,
    coaches: [],
    grounds: [],
    belts: [],
  });
  const handleOpenCreateCoachModal = () => setOpenCreateCoachModal(true);
  const handleCloseCreateCoachModal = () => setOpenCreateCoachModal(false);
  const handleOpenCreateGroundModal = () => setOpenCreateGroundModal(true);
  const handleCloseCreateGroundModal = () => setOpenCreateGroundModal(false);

  const handleAddCoach = (coach) => {
    setCreateState({
      ...createState,
      coaches: [...createState.coaches, coach],
    });
    handleCloseCreateCoachModal();
  };
  const handleRemoveCoach = (index) => {
    setCreateState({
      ...createState,
      coaches: createState.coaches.filter((c, i) => {
        if (index !== i) {
          return c;
        }
        return null;
      }),
    });
  };
  const handleAddGround = (ground) => {
    setCreateState({
      ...createState,
      grounds: [...createState.grounds, ground],
    });
    handleCloseCreateGroundModal();
  };
  const handleRemoveGround = (index) => {
    setCreateState({
      ...createState,
      grounds: createState.grounds.filter((g, i) => {
        if (index !== i) {
          return g;
        }
        return null;
      }),
    });
  };
  const handleCheckBelt = (id) => {
    if (!!createState.belts.find((b) => b === id)) {
      setCreateState({
        ...createState,
        belts: createState.belts.filter((b) => b !== id),
      });
    } else {
      setCreateState({
        ...createState,
        belts: [...createState.belts, id],
      });
    }
  };
  const handleSave = () => {
    if (
      (createState.belts.length &&
        createState.title &&
        createState.description &&
        createState.coaches.length &&
        createState.grounds.length &&
        createState.state,
      createState.city)
    ) {
      createEvent({
        ...createState,
      }).then((res) => {
        navigate("/");
      });
    }
  };
  return (
    <Dashboard title={"ایجاد رویداد"}>
      <Layout
        sx={{
          bgcolor: "background.body",
        }}
      >
        <Box
          sx={{
            pt: 1,
            pb: 1,
            mr: 3,
            mt: 1,
            display: "grid",
            gridTemplateColumns: {
              xs: "100%",
              sm: "minmax(120px, 30%) 1fr",
              lg: "220px 1fr minmax(120px, 208px)",
            },
            columnGap: { xs: 2, sm: 3, md: 4 },
            rowGap: { xs: 2, sm: 2.5 },
            "& > hr": {
              gridColumn: "1/-1",
            },
          }}
        >
          <Box>
            <FormLabel>اطلاعات اولیه </FormLabel>
            <FormHelperText>اطلاعات رویداد اعم از نام بازی و غیره</FormHelperText>
          </Box>

          <Box sx={{ display: { xs: "contents", sm: "flex" }, gap: 2 }}>
            <FormControl sx={{ flex: 1, gap: 1.5 }}>
              <FormLabel sx={{ display: { sm: "none" } }}>First name</FormLabel>
              <Input
                placeholder="نام رویداد"
                defaultValue=""
                value={createState.title}
                onChange={(e) => setCreateState({ ...createState, title: e.target.value })}
              />
              <Box sx={{ display: { xs: "contents", sm: "flex" }, gap: 1.5 }}>
                <FormControl size="sm" sx={{ flex: 1 }}>
                  <Select placeholder="استان" value={createState.state}>
                    <Option
                      value=""
                      onClick={() => setCreateState({ ...createState, state: null })}
                    >
                      همه استان ها
                    </Option>
                    {states.map((state) => (
                      <Option
                        value={state.id}
                        onClick={() => setCreateState({ ...createState, state: state.id })}
                      >
                        {state.title}
                      </Option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl size="sm" sx={{ flex: 1 }}>
                  <Select placeholder="شهر" value={createState.city}>
                    <Option value="" onClick={() => setCreateState({ ...createState, city: null })}>
                      همه شهر ها
                    </Option>
                    {cities.map((city) => (
                      <Option
                        value={city.id}
                        onClick={() => setCreateState({ ...createState, city: city.id })}
                      >
                        {city.title}
                      </Option>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Textarea
                minRows={4}
                placeholder="توضیحات"
                value={createState.description}
                onChange={(e) => setCreateState({ ...createState, description: e.target.value })}
              />
            </FormControl>
          </Box>

          <Divider role="presentation" />

          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"start"}
            justifyContent={"start"}
          >
            <FormLabel>مربی ها</FormLabel>
            <FormHelperText>برای ایجاد میتوانید روی به علاوه بزنید</FormHelperText>
            <Box sx={{ mt: 2 }}>
              <Button variant="outlined" size="sm" onClick={handleOpenCreateCoachModal}>
                ایجاد
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexWrap: "wrap",
              gap: 2.5,
            }}
          >
            {createState.coaches.length ? (
              <Card
                sx={{
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "repeat(4, 1fr)",
                }}
              >
                {createState.coaches.map((c, index) => (
                  <Card
                    variant="outlined"
                    sx={{
                      position: "relative",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ p: 1, bgcolor: "primary.100", borderRadius: "50%" }}>
                      <Box
                        sx={{
                          width: 32,
                          height: 32,
                          borderRadius: "50%",
                          bgcolor: "primary.200",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "primary.500",
                        }}
                      >
                        <LuUser size={20} />
                      </Box>
                    </Box>
                    {c.name}
                    <Typography level="body3" textAlign={"center"}>
                      {c.clubTitle}
                    </Typography>
                    <Box sx={{ position: "absolute", left: 5, top: 5 }}>
                      <IconButton
                        variant="plain"
                        size="sm"
                        color="danger"
                        onClick={() => handleRemoveCoach(index)}
                      >
                        <LuTrash2 />
                      </IconButton>
                    </Box>
                  </Card>
                ))}
              </Card>
            ) : (
              <Card
                variant="outlined"
                sx={[
                  {
                    borderRadius: "sm",
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    alignItems: "center",
                    px: 3,
                    flexGrow: 1,
                  },
                ]}
              >
                <Box sx={{ p: 1, bgcolor: "primary.100", borderRadius: "50%" }}>
                  <Box
                    sx={{
                      width: 32,
                      height: 32,
                      borderRadius: "50%",
                      bgcolor: "primary.200",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "primary.500",
                    }}
                  >
                    <LuUser size={20} />
                  </Box>
                </Box>
                <Typography level="body2" textAlign="center">
                  ایجاد زمین بازی. برای ایجاد روی{" "}
                  <Typography component={"span"} color="primary">
                    ایجاد
                  </Typography>{" "}
                  کلیک کنید
                  <br />
                  تعداد زمین های بازی تعداد بازی ها را تعیین می کنند
                </Typography>
              </Card>
            )}
          </Box>

          <Divider role="presentation" />

          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"start"}
            justifyContent={"start"}
          >
            <FormLabel>زمین های بازی</FormLabel>
            <FormHelperText>برای ایجاد میتوانید روی به علاوه بزنید</FormHelperText>
            <Box sx={{ mt: 2 }}>
              <Button variant="outlined" size="sm" onClick={handleOpenCreateGroundModal}>
                ایجاد
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexWrap: "wrap",
              gap: 2.5,
            }}
          >
            {createState.grounds.length ? (
              <Card
                sx={{
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "repeat(4, 1fr)",
                }}
              >
                {createState.grounds.map((g, index) => (
                  <Card
                    variant="outlined"
                    sx={{
                      position: "relative",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ p: 1, bgcolor: "primary.100", borderRadius: "50%" }}>
                      <Box
                        sx={{
                          width: 32,
                          height: 32,
                          borderRadius: "50%",
                          bgcolor: "primary.200",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "primary.500",
                        }}
                      >
                        <TbPlaystationSquare size={20} />
                      </Box>
                    </Box>
                    {g.title}

                    <Box sx={{ position: "absolute", left: 5, top: 5 }}>
                      <IconButton
                        variant="plain"
                        size="sm"
                        color="danger"
                        onClick={() => handleRemoveGround(index)}
                      >
                        <LuTrash2 />
                      </IconButton>
                    </Box>
                  </Card>
                ))}
              </Card>
            ) : (
              <Card
                variant="outlined"
                sx={[
                  {
                    borderRadius: "sm",
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    alignItems: "center",
                    px: 3,
                    flexGrow: 1,
                  },
                ]}
              >
                <Box sx={{ p: 1, bgcolor: "primary.100", borderRadius: "50%" }}>
                  <Box
                    sx={{
                      width: 32,
                      height: 32,
                      borderRadius: "50%",
                      bgcolor: "primary.200",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "primary.500",
                    }}
                  >
                    <TbPlaystationSquare size={20} />
                  </Box>
                </Box>
                <Typography level="body2" textAlign="center">
                  ایجاد زمین بازی. برای ایجاد روی{" "}
                  <Typography component={"span"} color="primary">
                    ایجاد
                  </Typography>{" "}
                  کلیک کنید
                  <br />
                  تعداد زمین های بازی تعداد بازی ها را تعیین می کنند
                </Typography>
              </Card>
            )}
          </Box>

          <Divider role="presentation" />

          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"start"}
            justifyContent={"start"}
          >
            <FormLabel>جنسیت بازیکن ها</FormLabel>
            <FormHelperText></FormHelperText>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexWrap: "wrap",
              gap: 2.5,
            }}
          >
            <Card
              sx={{
                width: "100%",
                direction: "rtl",
                overflow: "hidden",
              }}
            >
              <Radio
                checked={createState.sex === "MAN"}
                onClick={() => setCreateState({ ...createState, sex: "MAN" })}
                value="MAN"
                label={"مرد"}
              />
              <Radio
                checked={createState.sex === "WOMAN"}
                onClick={() => setCreateState({ ...createState, sex: "WOMAN" })}
                value="WOMAN"
                label={"زن"}
              />
            </Card>
          </Box>

          <Divider role="presentation" />
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"start"}
            justifyContent={"start"}
          >
            <FormLabel> کمربند ها </FormLabel>
            <FormHelperText>گروه بندی ها براساس کمربند های انتخابی انجام میشود</FormHelperText>
            <Box sx={{ mt: 2 }}></Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexWrap: "wrap",
              gap: 2.5,
            }}
          >
            <Card
              sx={{
                width: "100%",
                direction: "rtl",
                display: "grid",
                gridTemplateRows: "repeat(2, 1fr)",
                gridTemplateColumns: "repeat(3, 1fr)",
              }}
            >
              {belts &&
                belts.map((b, i) => (
                  <Box display={"flex"} gap={2} index={i}>
                    <Checkbox
                      checked={!!createState.belts.find((bi) => bi === b.id)}
                      onClick={() => handleCheckBelt(b.id)}
                    />
                    <Typography>{b.title}</Typography>
                  </Box>
                ))}
            </Card>
          </Box>

          <Divider role="presentation" />

          <Box
            sx={{
              gridColumn: "1/-1",
              justifySelf: "flex-end",
              display: "flex",
              gap: 1,
            }}
          >
            <Button variant="outlined" onClick={() => navigate(-1)} color="neutral" size="sm">
              لغو
            </Button>
            <Button size="sm" onClick={handleSave} loading={loading}>
              ذخیره
            </Button>
          </Box>
        </Box>

        {/* Modals  */}

        <CreateCoachModal
          open={openCreateCoachModal}
          onClose={handleCloseCreateCoachModal}
          onAdd={handleAddCoach}
        />

        <CreateGroundModal
          open={openCreateGroundModal}
          onClose={handleCloseCreateGroundModal}
          onAdd={handleAddGround}
        />
      </Layout>
    </Dashboard>
  );
}
