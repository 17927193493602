import { Card, Checkbox, IconButton, Radio } from "@mui/joy";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Textarea from "@mui/joy/Textarea";
import Typography from "@mui/joy/Typography";
import * as React from "react";
import { LuTrash2, LuUser } from "react-icons/lu";
import { TbPlaystationSquare } from "react-icons/tb";
import { useNavigate, useParams } from "react-router-dom";
import useReq from "../services";
import Layout from "./Layout";
import CreateCoachModal from "./Modals/CreateCoachModal";
import CreateGroundModal from "./Modals/CreateGroundModal";
import Dashboard from "./Dashboard";
import toast from "react-hot-toast";

export default function EditEvent() {
  const { state: cities } = useReq("get-cities");
  const { state: states } = useReq("get-states");
  const { state: belts } = useReq("get-belts");
  const { get: getEvent, loading } = useReq("get-event-by-id");
  const { get: removeEvent, loading: removeEventLoading } = useReq("remove-event");
  const { set: updateEvent, loading: updateEventLoading } = useReq("update-event");
  const { set: createGround, loading: createGroundLoading } = useReq("create-ground");
  const { get: removeGround, loading: removeGroundLoading } = useReq("remove-ground");
  const { set: createCoach, loading: createCoachLoading } = useReq("create-coach");
  const { get: removeCoach, loading: removeCoachLoading } = useReq("remove-coach");
  let { id } = useParams();

  const [openCreateCoachModal, setOpenCreateCoachModal] = React.useState(false);
  const navigate = useNavigate();
  const [openCreateGroundModal, setOpenCreateGroundModal] = React.useState(false);
  const [state, setState] = React.useState({
    description: "",
    title: "",
    sex: "MAN",
    city: null,
    state: null,
    coaches: [],
    grounds: [],
    belts: [],
  });
  const handleOpenCreateCoachModal = () => setOpenCreateCoachModal(true);
  const handleCloseCreateCoachModal = () => setOpenCreateCoachModal(false);
  const handleOpenCreateGroundModal = () => setOpenCreateGroundModal(true);
  const handleCloseCreateGroundModal = () => setOpenCreateGroundModal(false);

  const handleAddCoach = (coach) => {
    createCoach({
      event: parseInt(id),
      club: coach.clubId,
      name: coach.name,
    }).then((data) => {
      setState({
        ...state,
        coaches: [...state.coaches, data],
      });
      handleCloseCreateCoachModal();
    });
  };
  const handleRemoveCoach = (index) => {
    removeCoach([state.coaches[index]["id"]])
      .then(() => {
        setState({
          ...state,
          coaches: state.coaches.filter((c, i) => {
            if (index !== i) {
              return c;
            }
            return null;
          }),
        });
      })
      .catch((err) => {
        toast.error("خطا در حذف مربی");
      });
  };
  const handleAddGround = (ground) => {
    createGround({ title: ground.title, event: id })
      .then((res) => {
        setState({
          ...state,
          grounds: [...state.grounds, res],
        });
        handleCloseCreateGroundModal();
      })
      .catch((err) => console.log(err));
  };
  const handleRemoveGround = (index) => {
    removeGround([state.grounds[index]["id"]])
      .then(() => {
        setState({
          ...state,
          grounds: state.grounds.filter((g, i) => {
            if (index !== i) {
              return g;
            }
            return null;
          }),
        });
      })
      .catch((err) => console.log(err));
  };
  const handleCheckBelt = (id) => {
    if (!!state.belts.find((b) => b === id)) {
      setState({
        ...state,
        belts: state.belts.filter((b) => b !== id),
      });
    } else {
      setState({
        ...state,
        belts: [...state.belts, id],
      });
    }
  };
  const handleSave = () => {
    if (
      (state.belts.length &&
        state.title &&
        state.description &&
        state.coaches.length &&
        state.grounds.length &&
        state.state,
      state.city)
    ) {
      updateEvent({ ...state }, [id]).then((res) => {
        navigate(-1);
      });
    }
  };
  const handleRemoveEvent = () => {
    removeEvent([id])
      .then((res) => navigate("/"))
      .catch((err) => console.log(err));
  };
  React.useEffect(() => {
    if (id) {
      getEvent([id]).then((data) =>
        setState({
          ...data,
          city: data.city.id,
          state: data.state.id,
          belts: data.belts.map((b) => b.id),
        })
      );
    }
  }, [id, getEvent]);
  return (
    <Dashboard title={"ویرایش رویداد"}>
      <Layout
        loading={loading || removeCoachLoading || removeGroundLoading || removeEventLoading}
        sx={{
          bgcolor: "background.body",
          width: "100%",
        }}
      >
        <Box
          sx={{
            pt: 1,
            pb: 1,
            mr: 3,
            mt: 1,
            display: "grid",
            gridTemplateColumns: {
              xs: "100%",
              sm: "minmax(120px, 30%) 1fr",
              lg: "220px 1fr minmax(120px, 208px)",
            },
            columnGap: { xs: 2, sm: 3, md: 4 },
            rowGap: { xs: 2, sm: 2.5 },
            "& > hr": {
              gridColumn: "1/-1",
            },
          }}
        >
          <Box>
            <FormLabel>اطلاعات اولیه </FormLabel>
            <FormHelperText>اطلاعات رویداد اعم از نام بازی و غیره</FormHelperText>
          </Box>

          <Box sx={{ display: { xs: "contents", sm: "flex" }, gap: 2 }}>
            <FormControl sx={{ flex: 1, gap: 1.5 }}>
              <FormLabel sx={{ display: { sm: "none" } }}>First name</FormLabel>
              <Input
                placeholder="نام رویداد"
                defaultValue=""
                value={state.title}
                onChange={(e) => setState({ ...state, title: e.target.value })}
              />
              <Box sx={{ display: { xs: "contents", sm: "flex" }, gap: 1.5 }}>
                <FormControl size="sm" sx={{ flex: 1 }}>
                  <Select placeholder="استان" value={state.state}>
                    <Option value="" onClick={() => setState({ ...state, state: null })}>
                      همه استان ها
                    </Option>
                    {states.map((s) => (
                      <Option value={s.id} onClick={() => setState({ ...state, state: s.id })}>
                        {s.title}
                      </Option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl size="sm" sx={{ flex: 1 }}>
                  <Select placeholder="شهر" value={state.city}>
                    <Option value="" onClick={() => setState({ ...state, city: null })}>
                      همه شهر ها
                    </Option>
                    {cities.map((city) => (
                      <Option value={city.id} onClick={() => setState({ ...state, city: city.id })}>
                        {city.title}
                      </Option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl size="sm" sx={{ flex: 1 }}>
                  <Select placeholder="وضعیت" value={state.status}>
                    <Option value="" onClick={() => setState({ ...state, city: null })}>
                      همه وضعیت ها
                    </Option>
                    <Option value={"DOING"} onClick={() => setState({ ...state, status: "DOING" })}>
                      درحال برگذاری
                    </Option>
                    <Option
                      value={"FINISHED"}
                      onClick={() => setState({ ...state, status: "FINISHED" })}
                    >
                      تمام شده
                    </Option>
                    <Option
                      value={"CANCELED"}
                      onClick={() => setState({ ...state, status: "CANCELED" })}
                    >
                      لغو شده
                    </Option>
                  </Select>
                </FormControl>
              </Box>
              <Textarea
                minRows={4}
                placeholder="توضیحات"
                value={state.description}
                onChange={(e) => setState({ ...state, description: e.target.value })}
              />
              <Input
                placeholder=" مناسبت"
                defaultValue=""
                value={state.reason}
                onChange={(e) => setState({ ...state, reason: e.target.value })}
              />
            </FormControl>
          </Box>

          <Divider role="presentation" />

          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"start"}
            justifyContent={"start"}
          >
            <FormLabel>مربی ها</FormLabel>
            <FormHelperText>برای ایجاد میتوانید روی به علاوه بزنید</FormHelperText>
            <Box sx={{ mt: 2 }}>
              <Button variant="outlined" size="sm" onClick={handleOpenCreateCoachModal}>
                ایجاد
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexWrap: "wrap",
              gap: 2.5,
            }}
          >
            {state.coaches.length ? (
              <Card
                sx={{
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "repeat(4, 1fr)",
                }}
              >
                {state.coaches.map((c, index) => (
                  <Card
                    variant="outlined"
                    sx={{
                      position: "relative",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ p: 1, bgcolor: "primary.100", borderRadius: "50%" }}>
                      <Box
                        sx={{
                          width: 32,
                          height: 32,
                          borderRadius: "50%",
                          bgcolor: "primary.200",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "primary.500",
                        }}
                      >
                        <LuUser size={20} />
                      </Box>
                    </Box>
                    {c.name}
                    <Typography level="body3" textAlign={"center"}>
                      {c.club_title}
                    </Typography>
                    <Box sx={{ position: "absolute", left: 5, top: 5 }}>
                      <IconButton
                        variant="plain"
                        size="sm"
                        color="danger"
                        onClick={() => handleRemoveCoach(index)}
                      >
                        <LuTrash2 />
                      </IconButton>
                    </Box>
                  </Card>
                ))}
              </Card>
            ) : (
              <Card
                variant="outlined"
                sx={[
                  {
                    borderRadius: "sm",
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    alignItems: "center",
                    px: 3,
                    flexGrow: 1,
                  },
                ]}
              >
                <Box sx={{ p: 1, bgcolor: "primary.100", borderRadius: "50%" }}>
                  <Box
                    sx={{
                      width: 32,
                      height: 32,
                      borderRadius: "50%",
                      bgcolor: "primary.200",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "primary.500",
                    }}
                  >
                    <LuUser size={20} />
                  </Box>
                </Box>
                <Typography level="body2" textAlign="center">
                  ایجاد زمین بازی. برای ایجاد روی{" "}
                  <Typography component={"span"} color="primary">
                    ایجاد
                  </Typography>{" "}
                  کلیک کنید
                  <br />
                  تعداد زمین های بازی تعداد بازی ها را تعیین می کنند
                </Typography>
              </Card>
            )}
          </Box>

          <Divider role="presentation" />

          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"start"}
            justifyContent={"start"}
          >
            <FormLabel>زمین های بازی</FormLabel>
            <FormHelperText>برای ایجاد میتوانید روی به علاوه بزنید</FormHelperText>
            <Box sx={{ mt: 2 }}>
              <Button variant="outlined" size="sm" onClick={handleOpenCreateGroundModal}>
                ایجاد
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexWrap: "wrap",
              gap: 2.5,
            }}
          >
            {state.grounds.length ? (
              <Card
                sx={{
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "repeat(4, 1fr)",
                }}
              >
                {state.grounds.map((g, index) => (
                  <Card
                    variant="outlined"
                    sx={{
                      position: "relative",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ p: 1, bgcolor: "primary.100", borderRadius: "50%" }}>
                      <Box
                        sx={{
                          width: 32,
                          height: 32,
                          borderRadius: "50%",
                          bgcolor: "primary.200",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "primary.500",
                        }}
                      >
                        <TbPlaystationSquare size={20} />
                      </Box>
                    </Box>
                    {g.title}

                    <Box sx={{ position: "absolute", left: 5, top: 5 }}>
                      <IconButton
                        variant="plain"
                        size="sm"
                        color="danger"
                        onClick={() => handleRemoveGround(index)}
                      >
                        <LuTrash2 />
                      </IconButton>
                    </Box>
                  </Card>
                ))}
              </Card>
            ) : (
              <Card
                variant="outlined"
                sx={[
                  {
                    borderRadius: "sm",
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    alignItems: "center",
                    px: 3,
                    flexGrow: 1,
                  },
                ]}
              >
                <Box sx={{ p: 1, bgcolor: "primary.100", borderRadius: "50%" }}>
                  <Box
                    sx={{
                      width: 32,
                      height: 32,
                      borderRadius: "50%",
                      bgcolor: "primary.200",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "primary.500",
                    }}
                  >
                    <TbPlaystationSquare size={20} />
                  </Box>
                </Box>
                <Typography level="body2" textAlign="center">
                  ایجاد زمین بازی. برای ایجاد روی{" "}
                  <Typography component={"span"} color="primary">
                    ایجاد
                  </Typography>{" "}
                  کلیک کنید
                  <br />
                  تعداد زمین های بازی تعداد بازی ها را تعیین می کنند
                </Typography>
              </Card>
            )}
          </Box>

          <Divider role="presentation" />

          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"start"}
            justifyContent={"start"}
          >
            <FormLabel>جنسیت بازیکن ها</FormLabel>
            <FormHelperText></FormHelperText>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexWrap: "wrap",
              gap: 2.5,
            }}
          >
            <Card
              sx={{
                width: "100%",
                direction: "rtl",
                overflow: "hidden",
              }}
            >
              <Radio
                checked={state.sex === "MAN"}
                onClick={() => setState({ ...state, sex: "MAN" })}
                value="MAN"
                label={"مرد"}
              />
              <Radio
                checked={state.sex === "WOMAN"}
                onClick={() => setState({ ...state, sex: "WOMAN" })}
                value="WOMAN"
                label={"زن"}
              />
            </Card>
          </Box>

          <Divider role="presentation" />
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"start"}
            justifyContent={"start"}
          >
            <FormLabel> کمربند ها </FormLabel>
            <FormHelperText>گروه بندی ها براساس کمربند های انتخابی انجام میشود</FormHelperText>
            <Box sx={{ mt: 2 }}></Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexWrap: "wrap",
              gap: 2.5,
            }}
          >
            <Card
              sx={{
                width: "100%",
                direction: "rtl",
                display: "grid",
                gridTemplateRows: "repeat(2, 1fr)",
                gridTemplateColumns: "repeat(3, 1fr)",
              }}
            >
              {belts &&
                belts.map((b, i) => (
                  <Box display={"flex"} gap={2} index={i}>
                    <Checkbox
                      checked={!!state.belts.find((bi) => bi === b.id)}
                      onClick={() => handleCheckBelt(b.id)}
                    />
                    <Typography>{b.title}</Typography>
                  </Box>
                ))}
            </Card>
          </Box>

          <Divider role="presentation" />

          <Box
            sx={{
              gridColumn: "1/-1",
              justifySelf: "flex-end",
              display: "flex",
              gap: 1,
            }}
          >
            <Button variant="outlined" onClick={() => navigate(-1)} color="neutral" size="sm">
              لغو
            </Button>
            <Button onClick={handleRemoveEvent} color="danger" size="sm">
              حذف
            </Button>
            <Button size="sm" loading={updateEventLoading} onClick={handleSave}>
              ذخیره
            </Button>
          </Box>
        </Box>

        {/* Modals  */}

        <CreateCoachModal
          open={openCreateCoachModal}
          loading={createCoachLoading}
          onClose={handleCloseCreateCoachModal}
          onAdd={handleAddCoach}
        />

        <CreateGroundModal
          loading={createGroundLoading}
          open={openCreateGroundModal}
          onClose={handleCloseCreateGroundModal}
          onAdd={handleAddGround}
        />
      </Layout>
    </Dashboard>
  );
}
