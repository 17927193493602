import { Button, Option, Select, Sheet } from "@mui/joy";
import Box from "@mui/joy/Box";
import Dropdown from "@mui/joy/Dropdown";
import FormControl from "@mui/joy/FormControl";
import IconButton from "@mui/joy/IconButton";
import Input from "@mui/joy/Input";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import React from "react";
import { LuCheck, LuLayoutList, LuSettings, LuUser, LuX } from "react-icons/lu";
import { FORM_SUBMIT_OPTIONS } from "./usePlayerForm";

const PlayerForm = ({ event, playerForm, onCreate, onEdit }) => {
  const {
    coaches,
    handleChangeState,
    playerState,
    agesBySex,
    weightsByAge,
    handleClearPlayerState,
    formSubmitOption,
    setFormSubmitOption,
  } = playerForm;

  const handleChangeName = (e) => handleChangeState("name", e.target.value);
  const handleChangeAge = (_, newValue) => handleChangeState("age", newValue);
  const handleChangeBelt = (_, newValue) => handleChangeState("belt", newValue);
  const handleChangeWeight = (_, newValue) => handleChangeState("weight", newValue);
  const handleChangeCoach = (_, newValue) => handleChangeState("coach", newValue);

  return (
    <Sheet
      variant="outlined"
      className="SearchAndFilters-tabletUp"
      sx={{
        borderRadius: "sm",
        display: "flex",
        p: 1,
        gap: 1.5,
        mb: 1.5,
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "grid",
          gap: 1.5,
          flex: 1,
          gridTemplateColumns: "auto 15% 10% 15% 20% ",
        }}
      >
        <div style={{ textAlign: "right" }}>
          <FormControl sx={{ flex: 1 }} size="sm">
            <Input
              onChange={handleChangeName}
              placeholder="نام ورزشکار"
              startDecorator={<LuUser />}
              value={playerState.name}
            />
          </FormControl>
        </div>
        <div style={{ textAlign: "right" }}>
          <Box display={"flex"} alignItems={"center"} gap={1}>
            <Select
              sx={{ flex: 1 }}
              size="sm"
              name={"age"}
              onChange={handleChangeAge}
              placeholder="انتخاب رده سنی"
              value={playerState.age}
              slotProps={{
                button: { sx: { whiteSpace: "nowrap" } },
              }}
            >
              <Option value="">بدون انتخاب</Option>
              {agesBySex.map((a) => (
                <Option value={a.id}>{a.title}</Option>
              ))}
            </Select>
            <IconButton size="sm" variant="outlined">
              <LuLayoutList size={17} />
            </IconButton>
          </Box>
        </div>
        <div>
          <Box display={"flex"} alignItems={"center"} gap={1}>
            <Select
              sx={{ flex: 1 }}
              size="sm"
              placeholder="انتخاب وزن"
              onChange={handleChangeWeight}
              value={playerState.weight}
              slotProps={{
                button: { sx: { whiteSpace: "nowrap" } },
              }}
            >
              <Option value="">بدون انتخاب</Option>
              {weightsByAge.map((w, i) => (
                <Option key={i} value={w.id}>
                  {w.title}
                </Option>
              ))}
            </Select>
          </Box>
        </div>
        <div style={{ textAlign: "right" }}>
          <Box display={"flex"} alignItems={"center"}>
            <Select
              sx={{ flex: 1 }}
              size="sm"
              value={playerState.belt}
              placeholder="انتخاب کمربند"
              onChange={handleChangeBelt}
              slotProps={{
                button: { sx: { whiteSpace: "nowrap" } },
              }}
            >
              <Option value="">بدون انتخاب</Option>

              {event && event.belts.map((b, i) => <Option value={b.id}>{b.title}</Option>)}
            </Select>
          </Box>
        </div>

        <div>
          <Box display={"flex"} alignItems={"center"}>
            <Select
              sx={{ flex: 1 }}
              size="sm"
              value={playerState.coach}
              onChange={handleChangeCoach}
              placeholder="انتخاب مربی"
              slotProps={{
                button: { sx: { whiteSpace: "nowrap" } },
              }}
            >
              <Option value="">بدون انتخاب</Option>
              {coaches.map((c, i) => (
                <Option key={i} value={c.id}>
                  {c.name}
                </Option>
              ))}
            </Select>
          </Box>
        </div>
      </Box>
      {!playerState.id ? (
        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
          <Button size="sm" onClick={onCreate}>
            افزودن بازیکن
          </Button>
          <Dropdown>
            <MenuButton
              slots={{ root: IconButton }}
              slotProps={{ root: { variant: "outlined", color: "neutral" } }}
            >
              <LuSettings />
            </MenuButton>
            <Menu placement="bottom-end">
              <MenuItem
                onClick={() => setFormSubmitOption(FORM_SUBMIT_OPTIONS.createAndNew)}
                sx={{ direction: "rtl" }}
              >
                {formSubmitOption === FORM_SUBMIT_OPTIONS.createAndNew ? (
                  <ListItemDecorator>
                    <LuCheck />
                  </ListItemDecorator>
                ) : (
                  <ListItemDecorator />
                )}
                ساخت و جدید
              </MenuItem>
              <MenuItem
                onClick={() => setFormSubmitOption(FORM_SUBMIT_OPTIONS.createAndContinue)}
                sx={{ direction: "rtl" }}
              >
                {formSubmitOption === FORM_SUBMIT_OPTIONS.createAndContinue ? (
                  <ListItemDecorator>
                    <LuCheck />
                  </ListItemDecorator>
                ) : (
                  <ListItemDecorator />
                )}
                ساخت و ادامه
              </MenuItem>
            </Menu>
          </Dropdown>
        </Box>
      ) : (
        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
          <Button size="sm" color="neutral" onClick={onEdit}>
            ویرایش بازیکن
          </Button>
          <IconButton variant="plain" color="danger" size="sm" onClick={handleClearPlayerState}>
            <LuX />
          </IconButton>
        </Box>
      )}
    </Sheet>
  );
};

export default PlayerForm;
