import useReq from "../services";
import fileDownload from "js-file-download";
import {} from "jalaali-js";
import { georgianToJalaali } from "./date";

export const useGamePdfReport = () => {
  const { set: getPdf, loading } = useReq("generate-game-pdf");

  const handleGenerateGamesPdf = (games) => {
    if (!games && !games.length) return null;
    const formattedGames = games.map((game) => {
      return {
        player_one: (game.player_one && game.player_one.name) || "",
        player_two: (game.player_two && game.player_two.name) || "",
        noc_one:
          (game.player_one && game.player_one.coach && game.player_one.coach.club_title) || "",
        noc_two:
          (game.player_two && game.player_two.coach && game.player_two.coach.club_title) || "",
        number: String(game.number) || "",
        date: georgianToJalaali(new Date()),
        ground: (game.group && game.group.ground && game.group.ground.title) || "",
        weight: (game.group && game.group.weight && game.group.weight.title) || "",
        day_no: "اول",
      };
    });
    console.log(games, formattedGames);
    getPdf({ games: formattedGames })
      .then((data) =>
        fileDownload(new Blob([data], { type: "application/pdf;charset=UTF-8" }), "file.pdf")
      )
      .catch((err) => console.log(err));
  };
  const handleGenerateGamePdf = (game) => {
    if (!game) return null;
    handleGenerateGamesPdf([game]);
  };
  return {
    onGenerateGamesPdf: handleGenerateGamesPdf,
    onGenerateGamePdf: handleGenerateGamePdf,
    loading,
  };
};

export const useVerdictPdfReport = () => {
  const { set: getRatePdf, loading } = useReq("generate-rate-pdf");

  const handleGenerateVerdictsPdf = (items, eid) => {
    if (!items && !items.length) return null;
    const formattedItems = items.map((item) => {
      return {
        player: item.name,
        coach: item.coach.name,
        age: item.age.title,
        rate: "مقام " + item.rate.label,
      };
    });
    console.log(">>>", formattedItems);
    getRatePdf({
      event_id: eid,
      items: formattedItems,
    })
      .then((data) =>
        fileDownload(new Blob([data], { type: "application/pdf;charset=UTF-8" }), "file.pdf")
      )
      .catch((err) => console.log(err));
  };
  const handleGenerateVerdictPdf = (item, eid, position) => {
    if (!item) return null;
    handleGenerateVerdictsPdf([{ ...item, rate: { label: position } }], eid);
  };
  return {
    onGenerateVerdictsPdf: handleGenerateVerdictsPdf,
    onGenerateVerdictPdf: handleGenerateVerdictPdf,
    loading,
  };
};
