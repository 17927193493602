import { Box } from "@mui/joy";
import React from "react";
import { LuMinus, LuPlus } from "react-icons/lu";

const ScaleButtons = ({
  setScale,
  scale,
  left = null,
  right = null,
  top = null,
  bottom = null,
  position,
}) => {
  return (
    <Box
      position={position}
      top={top}
      bottom={bottom}
      left={left}
      right={right}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      p={0.5}
      gap={0.5}
      sx={{ bgcolor: "background.surface", borderRadius: { xs: "lg", md: "sm" }, boxShadow: "sm" }}
    >
      <Box
        onClick={() => setScale(scale + 0.2)}
        sx={{
          p: { xs: 1, md: 0.5 },
          fontSize: { xs: "xl2", md: "md" },
          borderRadius: { xs: "lg", md: "sm" },
          border: "1px solid",
          borderColor: "neutral.300",
          color: "neutral.600",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ":hover": {
            bgcolor: "primary.100",
          },
        }}
      >
        <LuPlus />
      </Box>
      <Box
        onClick={() => setScale(scale - 0.2)}
        sx={{
          p: { xs: 1, md: 0.5 },
          fontSize: { xs: "xl2", md: "md" },
          borderRadius: { xs: "lg", md: "sm" },
          border: "1px solid",
          borderColor: "neutral.300",
          color: "neutral.600",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ":hover": {
            bgcolor: "primary.100",
          },
        }}
      >
        <LuMinus />
      </Box>
    </Box>
  );
};

export default ScaleButtons;
