/* eslint-disable jsx-a11y/anchor-is-valid */
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Chip from "@mui/joy/Chip";
import FormControl from "@mui/joy/FormControl";
import IconButton, { iconButtonClasses } from "@mui/joy/IconButton";
import Input from "@mui/joy/Input";
import Link from "@mui/joy/Link";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import * as React from "react";
import {
  LuArrowDown,
  LuArrowLeft,
  LuArrowRight,
  LuCheck,
  LuCornerUpLeft,
  LuSearch,
  LuUser,
  LuX,
} from "react-icons/lu";
import { TbPlaystationSquare } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import useReq from "../services";
import { georgianToJalaali } from "../utils/date";
import Dashboard from "./Dashboard";
import Layout from "./Layout";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function Events() {
  const { state: events } = useReq("get-events");
  const [order, setOrder] = React.useState("desc");
  const navigate = useNavigate();
  const handleClick = (id) => {
    navigate(`/dashboard/event-detail/${id}`);
  };

  return (
    <Dashboard title={"رویداد ها"}>
      <Layout display={"flex"} flexDirection={"column"} height={"100%"}>
        <Box
          className="SearchAndFilters-tabletUp"
          sx={{
            borderRadius: "sm",
            pb: 2,
            display: {
              xs: "none",
              sm: "flex",
            },
            flexWrap: "wrap",
            gap: 1.5,
            "& > *": {
              minWidth: {
                xs: "120px",
                md: "160px",
              },
            },
          }}
        >
          <FormControl sx={{ flex: 1 }} size="sm">
            <Input placeholder="جستجو عنوان" startDecorator={<LuSearch />} />
          </FormControl>

          <FormControl size="sm">
            <Select placeholder="وضعیت" slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}>
              <Option value="">همه وضعیت ها</Option>
              <Option value="started">درحال انجام بازی</Option>
              <Option value="ended">تمام شده</Option>
              <Option value="canceled">لغو شده</Option>
            </Select>
          </FormControl>

          <FormControl size="sm">
            <Select placeholder="استان">
              <Option value="">همه استان ها</Option>
              <Option value="MAZ">مازندران</Option>
              <Option value="GUI">گیلان</Option>
            </Select>
          </FormControl>

          <FormControl size="sm">
            <Select placeholder="شهر">
              <Option value="">همه شهر ها</Option>
              <Option value="TON">تنکابن</Option>
              <Option value="RAM">رامسر</Option>
              <Option value="NASH">نشتارود</Option>
            </Select>
          </FormControl>
          <Button size="sm" onClick={() => navigate("/dashboard/create-event")}>
            رویداد جدید
          </Button>
        </Box>
        <Sheet
          className="OrderTableContainer"
          variant="outlined"
          sx={{
            width: "100%",
            borderRadius: "md",
            flex: 1,
            overflow: "auto",
            minHeight: 0,
          }}
        >
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            hoverRow
            sx={{
              "--TableCell-headBackground": (theme) => theme.vars.palette.background.level1,
              "--Table-headerUnderlineThickness": "1px",
              "--TableRow-hoverBackground": (theme) => theme.vars.palette.background.level1,
            }}
          >
            <thead>
              <tr>
                {/* <th style={{ width: 48, textAlign: "center", padding: 12 }}>
                <Checkbox
                  indeterminate={
                    selected.length > 0 && selected.length !== rows.length
                  }
                  checked={selected.length === rows.length}
                  onChange={(event) => {
                    setSelected(
                      event.target.checked ? rows.map((row) => row.id) : []
                    );
                  }}
                  color={
                    selected.length > 0 || selected.length === rows.length
                      ? "primary"
                      : undefined
                  }
                  sx={{ verticalAlign: "text-bottom" }}
                />
              </th> */}
                <th style={{ width: 70, padding: "12px 18px", textAlign: "right" }}>
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    onClick={() => setOrder(order === "asc" ? "desc" : "asc")}
                    fontWeight="lg"
                    endDecorator={<LuArrowDown />}
                    sx={{
                      "& svg": {
                        transition: "0.2s",
                        transform: order === "desc" ? "rotate(0deg)" : "rotate(180deg)",
                      },
                    }}
                  >
                    شناسه
                  </Link>
                </th>
                <th style={{ width: 250, padding: 12, textAlign: "right" }}>عنوان</th>
                <th style={{ width: 100, padding: 12, textAlign: "right" }}>تاریخ</th>
                <th style={{ width: 120, padding: 12, textAlign: "right" }}>وضعیت</th>
                <th style={{ width: 90, padding: 12, textAlign: "right" }}>مربیان</th>
                <th style={{ width: 90, padding: 12, textAlign: "right" }}>زمین ها</th>
                <th style={{ width: 80, padding: 12, textAlign: "right" }}>استان</th>
                <th style={{ width: 80, padding: 12, textAlign: "right" }}>شهر</th>
              </tr>
            </thead>
            <tbody>
              {events &&
                stableSort(events, getComparator(order, "id")).map((row) => (
                  <tr
                    key={row.id}
                    onClick={() => handleClick(row.id)}
                    style={{ cursor: "pointer" }}
                  >
                    <td style={{ textAlign: "right", padding: "0 15px" }}>
                      <Typography fontWeight="md">{row.id}</Typography>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <Typography fontWeight="md"> {row.title}</Typography>
                    </td>
                    <td style={{ textAlign: "right" }}>{georgianToJalaali(row.datetime)}</td>
                    <td style={{ textAlign: "right" }}>
                      <Chip
                        variant="soft"
                        size="sm"
                        startDecorator={
                          {
                            DOING: <LuCheck />,
                            FINISHED: <LuCornerUpLeft />,
                            CANCELED: <LuX />,
                          }[row.status]
                        }
                        color={
                          {
                            DOING: "success",
                            FINISHED: "neutral",
                            CANCELED: "danger",
                          }[row.status]
                        }
                      >
                        {
                          {
                            DOING: "درحال انجام بازی",
                            FINISHED: "تمام شده",
                            CANCELED: "لغو شده",
                          }[row.status]
                        }
                      </Chip>
                    </td>
                    <td>
                      <Box sx={{ display: "flex", gap: 1, alignItems: "right" }}>
                        <Avatar size="sm">
                          <LuUser size={20} />
                        </Avatar>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {row.coaches && row.coaches.length} نفر
                        </div>
                      </Box>
                    </td>
                    <td>
                      <Box sx={{ display: "flex", gap: 1, alignItems: "right" }}>
                        <Avatar size="sm">
                          <TbPlaystationSquare size={20} />
                        </Avatar>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {row.grounds && row.grounds.length} زمین
                        </div>
                      </Box>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <Typography fontWeight="lg" sx={{ display: "inline-block" }} color="neutral">
                        {row.state && row.state.title}
                      </Typography>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <Typography fontWeight="lg" sx={{ display: "inline-block" }} color="neutral">
                        {row.city && row.city.title}
                      </Typography>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Sheet>
        <Box
          className="Pagination-mobile"
          sx={{ display: { xs: "flex", md: "none" }, alignItems: "center" }}
        >
          <IconButton aria-label="صفحه قبلی" variant="outlined" color="neutral" size="sm">
            <LuArrowRight />
          </IconButton>
          <Typography level="body2" mx="auto">
            Page 1 of 10
          </Typography>
          <IconButton aria-label="صفحه بعدی" variant="outlined" color="neutral" size="sm">
            <LuArrowRight />
          </IconButton>
        </Box>
        <Box
          className="Pagination-laptopUp"
          sx={{
            pt: 4,
            gap: 1,
            [`& .${iconButtonClasses.root}`]: { borderRadius: "50%" },
            display: {
              xs: "none",
              md: "flex",
            },
          }}
        >
          <Button size="sm" variant="plain" color="neutral" startDecorator={<LuArrowRight />}>
            قبلی
          </Button>

          <Box sx={{ flex: 1 }} />
          {["1", "2", "3", "…", "8", "9", "10"].map((page) => (
            <IconButton
              key={page}
              size="sm"
              variant={Number(page) ? "outlined" : "plain"}
              color="neutral"
            >
              {page}
            </IconButton>
          ))}
          <Box sx={{ flex: 1 }} />

          <Button size="sm" variant="plain" color="neutral" endDecorator={<LuArrowLeft />}>
            بعدی
          </Button>
        </Box>
      </Layout>
    </Dashboard>
  );
}
