import {
  Box,
  Button,
  Divider,
  IconButton,
  Input,
  Modal,
  ModalDialog,
  Option,
  Select,
  Textarea,
  Typography,
} from "@mui/joy";
import React, { useEffect, useState } from "react";
import { LuPrinter } from "react-icons/lu";
import { MdOutlineScoreboard } from "react-icons/md";
import useReq from "../../services/index";
import { useGamePdfReport, useVerdictPdfReport } from "../../utils/pdfReports";

const winningStatuses = [
  { label: " نامشخص", key: "UNK" },
  { label: "مبارز آبی", key: "ONE" },
  { label: "مبارز قرمز", key: "TWO" },
];

const INITIAL_STATE = {
  winner: winningStatuses[0],
  score_one: 0,
  score_two: 0,
  description: "",
};

const GameModal = ({ onClose, game, onChange }) => {
  const [state, setState] = useState(INITIAL_STATE);
  const { set: updateGame, loading: updateGameLoading } = useReq("update-game");
  const { onGenerateGamePdf, loading: gamePdfLoading } = useGamePdfReport();
  const { onGenerateVerdictPdf, loading: generateVerdictPdfLoading } = useVerdictPdfReport();

  const handleChangeState = (key, value) => {
    setState({ ...state, [key]: value });
  };
  const handleSave = () => {
    updateGame({
      game_id: game.id,
      ...state,
    }).then((data) => {
      console.log(data);
      onChange();
    });
  };
  const handleCloseModal = () => {
    setState(INITIAL_STATE);
    onClose();
  };

  useEffect(() => {
    if (game) {
      setState({
        winner: game.winner,
        score_one: game.score_one,
        score_two: game.score_two,
        description: game.description,
      });
    }
  }, [game]);
  const handleGetPdf = () => {
    onGenerateGamePdf(game);
  };
  const handleGetRatePdf = (player, position) => {
    if (player) {
      onGenerateVerdictPdf(player, game.event, position);
    }
  };

  if (!game) return null;
  return (
    <Modal
      open={!!game}
      onClose={handleCloseModal}
      sx={{
        direction: "rtl",
        zIndex: 10000,
      }}
    >
      <ModalDialog sx={{ width: 700, p: 1 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            gap: 2,
            p: 1,
            width: "100%",
          }}
        >
          <Box width={"48%"}>
            <Box display={"flex"} alignItems={"center"} gap={1}>
              <Box sx={{ width: 30, height: 5, borderRadius: "md", bgcolor: "primary.600" }}></Box>
              <Typography level="body3">مبارز آبی</Typography>
            </Box>
            <Typography px={1} level="h5" mb={1}>
              {game.player_one && game.player_one.name}
            </Typography>

            <Box
              display={"flex"}
              gap={1}
              p={1}
              border={"1px solid"}
              borderColor={"neutral.100"}
              borderRadius={"sm"}
            >
              <Typography px={1} level="body2" noWrap>
                مربی: {game.player_one && game.player_one.coach.name}
              </Typography>
              <Typography px={1} level="body2" noWrap>
                باشکاه: {game.player_one && game.player_one.coach.club_title}
              </Typography>
            </Box>

            {game.reverse_level === 1 && game.winner !== "UNK" && (
              <Box
                display={"flex"}
                gap={1}
                mt={1}
                p={0.5}
                border={"1px solid"}
                borderColor={"neutral.100"}
                borderRadius={"sm"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography px={1} level="body2" noWrap>
                  مقام {game.winner === "ONE" ? "اول" : "دوم"}
                </Typography>
                <IconButton
                  size="sm"
                  loading={generateVerdictPdfLoading}
                  onClick={() =>
                    handleGetRatePdf(game.player_one, game.winner === "ONE" ? "اول" : "دوم")
                  }
                >
                  <LuPrinter />
                </IconButton>
              </Box>
            )}
            {game.reverse_level === 2 && game.winner !== "UNK" && game.winner === "TWO" && (
              <Box
                display={"flex"}
                gap={1}
                mt={1}
                p={0.5}
                border={"1px solid"}
                borderColor={"neutral.100"}
                borderRadius={"sm"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography px={1} level="body2" noWrap>
                  مقام سوم
                </Typography>
                <IconButton
                  size="sm"
                  loading={generateVerdictPdfLoading}
                  onClick={() => handleGetRatePdf(game.player_one, "سوم")}
                >
                  <LuPrinter />
                </IconButton>
              </Box>
            )}
          </Box>
          <Box sx={{ width: "1px", height: "auto", bgcolor: "neutral.200" }} />
          <Box width={"48%"}>
            <Box display={"flex"} alignItems={"center"} gap={1}>
              <Box sx={{ width: 30, height: 5, borderRadius: "md", bgcolor: "red.solid" }}></Box>
              <Typography level="body3">مبارز قرمز</Typography>
            </Box>
            <Typography px={1} level="h5" mb={1}>
              {game.player_two && game.player_two.name}
            </Typography>

            <Box
              display={"flex"}
              gap={0.5}
              p={1}
              border={"1px solid"}
              borderColor={"neutral.100"}
              borderRadius={"sm"}
            >
              <Typography px={1} level="body2" flex={1} noWrap>
                مربی: {game.player_two && game.player_two.coach.name}
              </Typography>
              <Typography px={1} flex={1} level="body2" noWrap>
                باشکاه: {game.player_two && game.player_two.coach.club_title}
              </Typography>
            </Box>
            {game.reverse_level === 1 && game.winner !== "UNK" && (
              <Box
                display={"flex"}
                gap={1}
                mt={1}
                p={0.5}
                border={"1px solid"}
                borderColor={"neutral.100"}
                borderRadius={"sm"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography px={1} level="body2" noWrap>
                  مقام {game.winner === "TWO" ? "اول" : "دوم"}
                </Typography>
                <IconButton
                  size="sm"
                  onClick={() =>
                    handleGetRatePdf(game.player_two, game.winner === "TWO" ? "اول" : "دوم")
                  }
                >
                  <LuPrinter />
                </IconButton>
              </Box>
            )}
            {game.reverse_level === 2 && game.winner !== "UNK" && game.winner === "ONE" && (
              <Box
                display={"flex"}
                gap={1}
                mt={1}
                p={0.5}
                border={"1px solid"}
                borderColor={"neutral.100"}
                borderRadius={"sm"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography px={1} level="body2" noWrap>
                  مقام سوم
                </Typography>
                <IconButton size="sm" onClick={() => handleGetRatePdf(game.player_one, "سوم")}>
                  <LuPrinter />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
        <Box display={"flex"} gap={2} px={1} py={1}>
          <Input
            placeholder="امتیاز آبی"
            startDecorator={<MdOutlineScoreboard />}
            type="number"
            value={state.score_one || null}
            onChange={(e) => handleChangeState("score_one", e.target.value)}
          />
          <Select
            sx={{ width: "50%" }}
            size="sm"
            name={"age"}
            onChange={(e, value) => handleChangeState("winner", value)}
            placeholder="برنده"
            value={state.winner}
            slotProps={{
              button: { sx: { whiteSpace: "nowrap" } },
              listbox: { sx: { zIndex: 10000000000 } },
            }}
          >
            {[...winningStatuses].map((a) => (
              <Option value={a.key} key={a.key}>
                {a.label}
              </Option>
            ))}
          </Select>
          <Input
            placeholder="امتیاز آبی"
            startDecorator={<MdOutlineScoreboard />}
            type="number"
            value={state.score_two || null}
            onChange={(e) => handleChangeState("score_two", e.target.value)}
          />
        </Box>
        <Box
          sx={{
            border: "1px solid",
            borderColor: "neutral.100",
            py: 1,
            my: 2,
            mx: 1,
            display: "flex",
            gap: 5,
            px: 1,
            borderRadius: "sm",
          }}
        >
          <Typography>زمین بازی: {game.ground && game.ground.title}</Typography>
          <Typography>شماره بازی: {game.ground && game.ground.title}</Typography>
          <Typography> کمربند: {game.group && game.group.belt.title}</Typography>
          <Typography> وزن: {game.group && game.group.weight.title}</Typography>
          <Typography> سن: {game.group && game.group.age.title}</Typography>
        </Box>
        <Textarea
          sx={{ height: 70, mb: 2 }}
          value={state.description}
          onChange={(e) => handleChangeState("description", e.target.value)}
        />
        <Box>
          <Divider />
        </Box>
        <Box sx={{ pt: 2, pb: 1, px: 2, display: "flex", gap: 1, justifyContent: "space-between" }}>
          <Button loading={gamePdfLoading} variant="outlined" size="sm" onClick={handleGetPdf}>
            چاپ
          </Button>
          <Box
            sx={{
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
              flexDirection: "row-reverse",
              gap: 1,
            }}
          >
            <Button loading={updateGameLoading} size="sm" onClick={handleSave}>
              ذخیره
            </Button>
            <Button size="sm" variant="soft" onClick={handleCloseModal}>
              لغو
            </Button>
          </Box>
        </Box>
      </ModalDialog>
    </Modal>
  );
};

export default GameModal;
