import { Box, Chip, Divider, IconButton, Typography } from "@mui/joy";
import React, { useMemo } from "react";
import { LuAlignJustify, LuFolderTree, LuListStart, LuMedal, LuPrinter } from "react-icons/lu";
import StartedGroupItem from "./StartedGroupItem";
import { useNavigate } from "react-router-dom";

const RATES = {
  first: { id: 1, label: "اول", color: "#FFD700" },
  second: { id: 2, label: "دوم", color: "#C0C0C0" },
  third: { id: 3, label: "سوم", color: "#cc663388" },
};

const GroupItem = ({ games, group, onCreateBulkRatePdf }) => {
  const finishedGames = games.reduce((acc, curr) => {
    if (curr.winner !== "UNK") {
      acc += 1;
      return acc;
    }
    return acc;
  }, 0);
  const allGames = games.length;
  const navigate = useNavigate();
  const winners = useMemo(() => {
    if (games.length && finishedGames === games.length) {
      const level1 = games.filter((item) => item.reverse_level === 1);
      const level2 = games.filter((item) => item.reverse_level === 2);

      return [
        { ...level1[0].player_one, rate: RATES.first },
        { ...level1[0].player_two, rate: RATES.second },
        ...level2
          .filter((g) => Boolean(g.player_two))
          .map((g) => ({ ...g.player_two, rate: RATES.third })),
      ].filter(Boolean);
    }
    return [];
  }, [finishedGames, games]);

  const handleCreateBulkRatePdf = () => {
    onCreateBulkRatePdf(winners);
  };
  return (
    <React.Fragment>
      <Box alignItems={"center"} display={"flex"} gap={1.5} pb={1}>
        {allGames ? (
          <IconButton onClick={() => navigate(`/dashboard/group-games/${group.id}`)}>
            <LuFolderTree size={20} />
          </IconButton>
        ) : (
          <Box
            sx={{
              borderRadius: "50%",
              p: 1.3,
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <LuAlignJustify />
          </Box>
        )}

        <Box display={"flex"} flexDirection={"column"}>
          <Typography>
            {group.age.title} {group.belt.title} {group.weight.title}
          </Typography>
          <Typography level="body3">تعداد: {group && group.players_count} نفر</Typography>
        </Box>
      </Box>
      <Divider />
      {allGames && !winners.length ? (
        <StartedGroupItem allGames={allGames} finishedGames={finishedGames} />
      ) : winners.length ? (
        <Box
          pt={1}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"end"}
          px={0.5}
          gap={1}
        >
          <Chip variant="soft" color={"secondary"} size="sm" startDecorator={<LuListStart />}>
            تمام شده
          </Chip>
          <Box
            display={"flex"}
            gap={1}
            mt={1}
            p={0.5}
            px={2}
            border={"1px solid"}
            borderColor={"neutral.100"}
            borderRadius={"sm"}
            flexDirection={"column"}
          >
            <Box display={"flex"} pt={0.5} justifyContent={"space-between"} alignItems={"center"}>
              <Typography>برندگان</Typography>
              <IconButton size="sm" onClick={handleCreateBulkRatePdf}>
                <LuPrinter />
              </IconButton>
            </Box>
            <Divider />
            <Box display={"flex"} pt={0.5} gap={0.8} flexDirection={"column"}>
              {winners.map((w, i) => (
                <Box key={i} display={"flex"} gap={1} alignItems={"center"}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: 25,
                      height: 25,
                      bgcolor: w.rate && w.rate.color,
                      color: "#000",
                      borderRadius: "50%",
                    }}
                  >
                    <LuMedal />
                  </Box>
                  <Typography level="body-sm">{w.rate && w.rate.label}</Typography>
                  <Typography>{w.name}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box pt={1} display={"flex"} alignItems={"center"} justifyContent={"end"} px={0.5}>
          <Chip variant="soft" color={"primary"} size="sm" startDecorator={<LuListStart />}>
            شروع نشده
          </Chip>
        </Box>
      )}
    </React.Fragment>
  );
};

export default GroupItem;
