import { Box, Card, Sheet } from "@mui/joy";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import GroundItemContainer from "./GroundItemContainer";
import GroupItem from "./GroupItem";

const Grounds = ({
  allGamesByGroup = [],
  onDragEnd,
  onReset,
  groundsList,
  onStartGame,
  onGetRatePdf,
  onDistributeGroups,
}) => {
  return (
    <Box
      p={1.5}
      gap={1.5}
      flexDirection={"column"}
      display={"flex"}
      sx={{
        bgcolor: "var(--joy-palette-background-surface)",
        width: 400,
        height: "100%",
        overflowY: "scroll",
      }}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        {groundsList.lists.map((g, gi) => (
          <Droppable key={gi} droppableId={String(gi)} isDropDisabled={g.started}>
            {(provided, _) => (
              <Card
                variant="outlined"
                sx={{ ...(g.disableStartButton ? { bgcolor: "primary.100" } : {}) }}
                ref={provided.innerRef}
                pt={0.5}
              >
                <GroundItemContainer
                  onDistribute={onDistributeGroups}
                  onReset={() => onReset(g.id)}
                  ground={g}
                  onStart={() => onStartGame(gi)}
                >
                  {g.groups.map((gp, gpi) => (
                    <Draggable
                      key={`item-${gp.id}`}
                      isDragDisabled={g.started}
                      draggableId={`item-${gp.id}`}
                      index={gpi}
                    >
                      {(provided, snapshot) => (
                        <Sheet
                          key={gpi}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          variant="outlined"
                          sx={{ borderRadius: "var(--joy-radius-md)", p: 1, userSelect: "none" }}
                        >
                          <GroupItem
                            onCreateBulkRatePdf={onGetRatePdf}
                            group={gp}
                            games={allGamesByGroup[gp.id] || []}
                          />
                        </Sheet>
                      )}
                    </Draggable>
                  ))}
                </GroundItemContainer>
                {provided.placeholder}
              </Card>
            )}
          </Droppable>
        ))}
      </DragDropContext>
    </Box>
  );
};

export default Grounds;
