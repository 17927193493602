import { Box, Button, Divider, Typography } from "@mui/joy";
import React from "react";

import Dropdown from "@mui/joy/Dropdown";
import IconButton from "@mui/joy/IconButton";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import { LuSettings } from "react-icons/lu";

const GroundItemContainer = ({ children, onStart, ground, onReset, onDistribute }) => {
  return (
    <React.Fragment>
      {!ground.disableStartButton ? (
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          pb={0.5}
        >
          <Typography fontWeight={"bold"}>{ground.title}</Typography>
          {ground && ground.started ? (
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <Typography
                level="body-xs"
                color="secondary"
                variant="soft"
                sx={{ px: 1, py: 0.7, borderRadius: "sm" }}
              >
                شروع شده
              </Typography>
              <Dropdown>
                <MenuButton
                  slots={{ root: IconButton }}
                  slotProps={{ root: { variant: "outlined", color: "neutral", size: "sm" } }}
                >
                  <LuSettings />
                </MenuButton>
                <Menu placement="bottom-end">
                  <MenuItem onClick={onReset} sx={{ direction: "rtl" }}>
                    حذف بازی های زمین
                  </MenuItem>
                </Menu>
              </Dropdown>
            </Box>
          ) : (
            <Button color="secondary" size={"sm"} onClick={onStart}>
              شروع بازی ها
            </Button>
          )}
        </Box>
      ) : (
        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
          <Typography>بدون زمین</Typography>
          <Button onClick={onDistribute}>تقسیم گروه ها</Button>
        </Box>
      )}
      <Divider />
      {children}
    </React.Fragment>
  );
};

export default GroundItemContainer;
