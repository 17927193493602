import { Box, IconButton, Sheet, Typography } from "@mui/joy";
import React from "react";
import { LuEdit } from "react-icons/lu";

const GameItem = ({ game, onOpenGameDetail }) => {
  const winner =
    game.winner === "ONE"
      ? game.player_one.name
      : game.winner === "TWO"
      ? game.player_two.name
      : null;
  return (
    <Sheet
      variant="outlined"
      sx={{
        p: 2,
        m: 1,
        borderRadius: "sm",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography>{game.player_one.name}</Typography>
          <Typography fontSize={"xs"} level="body4">
            مربی: {game.player_one.coach.name}
          </Typography>
          <Typography fontSize={"xs"} level="body4">
            باشگاه: {game.player_one.coach.club_title}
          </Typography>
        </Box>
        <Box
          sx={{
            color: "primary.500",
            bgcolor: "primary.100",
            width: "30px",
            height: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            fontSize: "14px",
          }}
        >
          vs
        </Box>
        <Box>
          <Typography>{game.player_two.name}</Typography>
          <Typography fontSize={"xs"} level="body4">
            مربی: {game.player_two.coach.name}
          </Typography>
          <Typography fontSize={"xs"} level="body4">
            باشگاه: {game.player_two.coach.club_title}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          borderTop: "1px solid #eee",
          mt: 1,
          pt: 1,
          display: "flex",
          alignItems: "center",
          gap: 1.5,
        }}
      >
        <Typography level="body1" color={winner ? "primary" : "secondary"}>
          برنده: {winner || "نامشخص"}
        </Typography>
        <Typography level="body2" color="primary">
          {winner && (
            <>
              نتیجه {game.score_one}-{game.score_two}
            </>
          )}
        </Typography>
        <Box flex={1} display={"flex"} flexDirection={"row-reverse"} alignItems={"center"}>
          <IconButton size="sm" variant="outlined" onClick={() => onOpenGameDetail()}>
            <LuEdit />
          </IconButton>
        </Box>
      </Box>
    </Sheet>
  );
};

export default GameItem;
