import { IconButton, Sheet } from "@mui/joy";
import Box from "@mui/joy/Box";
import * as React from "react";
import { LuArrowLeft } from "react-icons/lu";
import { MdOutlineEmojiEvents } from "react-icons/md";

import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Toast from "./Toast";

export default function Dashboard({ children, title }) {
  const hasPadding = false;
  const navigate = useNavigate();
  return (
    <Box sx={{ display: "flex", minHeight: "100dvh" }}>
      <Header />
      <Sidebar />
      <Box
        sx={(theme) => ({
          flex: 1,
          display: "flex",
          flexDirection: "column",
          minWidth: 0,
          height: "100dvh",
        })}
      >
        <Sheet
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            py: 1,
            px: 3,
            zIndex: 2000,
            width: "100%",
            boxShadow: "1px 0px 2px #00000022",
          }}
        >
          <MdOutlineEmojiEvents size={20} />
          <Box>{title}</Box>
          <Box flex={1} display={"flex"} alignItems={"center"} flexDirection={"row-reverse"}>
            <IconButton variant="outlined" size="sm" onClick={() => navigate(-1)}>
              <LuArrowLeft />
            </IconButton>
          </Box>
          {/* <Breadcrumbs separator={<LuChevronLeft />} aria-label="breadcrumbs" size="md">
            {["رویدادها", "ویرایش رویداد"].map((item, index) => (
              <Link
                // `preventDefault` is for demo purposes
                // and is generally not needed in your app
                onClick={(event) => event.preventDefault()}
                key={item}
                underline="hover"
                color="neutral"
                fontSize="inherit"
                href="/"
              >
                {item}
              </Link>
            ))}
            <Typography fontSize="inherit">بازی های زمین</Typography>
          </Breadcrumbs> */}
        </Sheet>
        <Box
          sx={(theme) => ({
            "--main-paddingTop": {
              xs: `calc(${theme.spacing(2)} + var(--Header-height, 0px))`,
              md: "32px",
            },
            ...(hasPadding
              ? {
                  px: {
                    xs: 2,
                    md: 3,
                  },
                  pt: "var(--main-paddingTop)",
                  pb: {
                    xs: 2,
                    sm: 2,
                    md: 3,
                  },
                }
              : {}),
            flex: 1,
            display: "flex",
            flexDirection: "column",
            minWidth: 0,
            height: "100dvh",
            gap: 1,
            position: "relative",
            overflow: "auto",
          })}
        >
          {children}
        </Box>
      </Box>
      <Toast />
    </Box>
  );
}
