import { Button, Card, Chip, Typography } from "@mui/joy";
import Box from "@mui/joy/Box";
import * as React from "react";
import { LuFolderOpen, LuFolderTree, LuUser } from "react-icons/lu";
import { TbPlaystationSquare } from "react-icons/tb";
import { Link, useParams } from "react-router-dom";
import useReq from "../services";
import Dashboard from "./Dashboard";
import Layout from "./Layout";

export default function EventDetail() {
  const { id } = useParams();
  const [state, setState] = React.useState(null);
  const { get: getEvent } = useReq("get-event-by-id");
  React.useEffect(() => {
    if (id) {
      getEvent([id]).then((data) =>
        setState({
          ...data,
        })
      );
    }
  }, [id, getEvent]);
  return (
    <Dashboard title={"جزییات رویداد"}>
      <Layout
        sx={{
          bgcolor: "background.body",
          flex: 1,
          width: "100%",
          mx: "auto",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Card style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Box
            sx={{
              borderBottom: "1px solid",
              borderBottomColor: "neutral.100",
              pb: 2,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              px: 2,
              mx: 2,
            }}
          >
            <Typography>جزییات رویداد</Typography>
            <Link to={`/dashboard/edit-event/${id}`}>
              <Button size="sm">ویرایش</Button>
            </Link>
          </Box>
          <Box display={"flex"} px={3} gap={3} overflow={"auto"} height={"calc(80vh - 10rem)"}>
            <Box
              flex={1}
              display={"flex"}
              gap={2}
              flexDirection={"column"}
              position={"sticky"}
              top={0}
              sx={{ borderLeft: "1px solid", borderColor: "neutral.200" }}
            >
              <Box>
                <Typography level="body2">عنوان</Typography>
                <Typography level="h4">{state && state.title}</Typography>
                <Typography level="body2">{state && state.reason}</Typography>
              </Box>
              <Box display={"flex"} flexDirection={"column"} alignItems={"start"}>
                <Typography level="title-sm">جنسیت مبارزان</Typography>
                <Typography
                  border={"1px solid"}
                  borderColor={"primary.200"}
                  py={0.5}
                  borderRadius={"sm"}
                  level="body1"
                  mt={1}
                  px={5}
                >
                  {state && state.sex === "WOMAN" ? "زن" : "مرد"}
                </Typography>
              </Box>
              <Box display={"flex"} flexDirection={"column"} alignItems={"start"}>
                <Typography level="body2">کمربند ها</Typography>
                <Box display={"flex"} pt={1} flexDirection={"row"} alignItems={"center"} gap={1}>
                  {state &&
                    state.belts.map((b, i) => (
                      <Chip size="sm" color="secondary" variant="solid" key={i}>
                        {b.title}
                      </Chip>
                    ))}
                </Box>
              </Box>
              <Box>
                <Typography level="body2">توضیحات</Typography>
                <Typography>{state && state.description}</Typography>
              </Box>
              {state && (
                <Typography level="body2">
                  {
                    {
                      DOING: "درحال انجام بازی",
                      FINISHED: "تمام شده",
                      CANCELED: "لغو شده",
                    }[state.status]
                  }
                </Typography>
              )}
            </Box>
            <Box flex={1} display={"flex"} flexDirection={"column"} gap={2}>
              <Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      bgcolor: "primary.100",
                      width: 30,
                      height: 30,
                      color: "primary.500",
                      borderRadius: "50%",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <LuUser size={20} />
                  </Box>
                  <Typography level="h6" pr={1}>
                    مربیان
                  </Typography>
                </Box>

                <Box
                  // maxHeight={200}
                  border={"1px solid"}
                  borderColor={"neutral.100"}
                  borderRadius={"md"}
                  overflow={"auto"}
                  mt={1}
                >
                  {state &&
                    state.coaches.map((c, i) => (
                      <Box
                        key={i}
                        py={1}
                        mx={1}
                        borderBottom={"1px solid"}
                        borderColor={"neutral.100"}
                      >
                        {c.name}
                      </Box>
                    ))}
                </Box>
              </Box>
              <Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      bgcolor: "primary.100",
                      width: 30,
                      height: 30,
                      color: "primary.500",
                      borderRadius: "50%",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <TbPlaystationSquare size={20} />
                  </Box>
                  <Typography level="h6" pr={1}>
                    زمین بازی
                  </Typography>
                </Box>
                <Box
                  // maxHeight={200}
                  // height={200}
                  border={"1px solid"}
                  borderColor={"neutral.100"}
                  borderRadius={"md"}
                  overflow={"auto"}
                  mt={1}
                >
                  {state &&
                    state.grounds.map((g, i) => (
                      <Box
                        key={i}
                        py={1}
                        mx={1}
                        borderBottom={"1px solid"}
                        borderColor={"neutral.100"}
                      >
                        زمین: {g.title}
                      </Box>
                    ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Card>

        <Box display={"flex"} flexDirection={"row"} width={"100%"} height={"100%"} gap={2}>
          <Link
            style={{ textDecoration: "none", flex: 1, height: "100%", display: "flex" }}
            to={`/dashboard/members?event_id=${id}`}
          >
            <Card
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",

                bgcolor: "primary.500",
                color: "background.sheet",

                ">.icon-container": { bgcolor: "primary.600", color: "background.sheet" },
                ":hover>.icon-container": { bgcolor: "primary.700" },
                ":hover": { bgcolor: "primary.600", color: "background.sheet" },
                ">.MuiTypography-root": { color: "background.sheet" },
              }}
            >
              <Box
                className={"icon-container"}
                sx={{
                  width: 50,
                  height: 50,
                  bgcolor: "primary.100",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  fontSize: 20,
                }}
              >
                <LuUser />
              </Box>
              <Typography>مبارزان</Typography>
            </Card>
          </Link>
          <Link
            style={{ textDecoration: "none", flex: 1, height: "100%", display: "flex" }}
            to={`/dashboard/groups/${id}`}
          >
            <Card
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",

                bgcolor: "primary.500",
                color: "background.sheet",

                ">.icon-container": { bgcolor: "primary.600", color: "background.sheet" },
                ":hover>.icon-container": { bgcolor: "primary.700" },
                ":hover": { bgcolor: "primary.600", color: "background.sheet" },
                ">.MuiTypography-root": { color: "background.sheet" },
              }}
            >
              <Box
                className={"icon-container"}
                sx={{
                  width: 50,
                  height: 50,
                  bgcolor: "primary.100",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  fontSize: 20,
                }}
              >
                <LuFolderOpen />
              </Box>
              <Typography>گروه ها</Typography>
            </Card>
          </Link>
          <Link
            style={{ textDecoration: "none", flex: 1, height: "100%", display: "flex" }}
            to={`/dashboard/grounds/${id}`}
          >
            <Card
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",

                bgcolor: "primary.500",
                color: "background.sheet",

                ">.icon-container": { bgcolor: "primary.600", color: "background.sheet" },
                ":hover>.icon-container": { bgcolor: "primary.700" },
                ":hover": { bgcolor: "primary.600", color: "background.sheet" },
                ">.MuiTypography-root": { color: "background.sheet" },
              }}
            >
              <Box
                className={"icon-container"}
                sx={{
                  width: 50,
                  height: 50,
                  bgcolor: "primary.100",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  fontSize: 20,
                }}
              >
                <LuFolderTree />
              </Box>
              <Typography>بازی ها</Typography>
            </Card>
          </Link>
        </Box>
      </Layout>
    </Dashboard>
  );
}
