import React from "react";

const BeltColorCircle = ({ style, color }) => {
  const [color1, color2] = color
    ? [...color.split("-")].reduce(
        (acc, curr, currIndex) => {
          acc[currIndex] = curr;
          return acc;
        },
        ["transparent", null]
      )
    : ["transparent", null];
  return (
    <div
      style={{
        borderRadius: "50%",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        aspectRatio: "1/1",
        height: style.height || "100%",
        ...style,
      }}
    >
      <div style={{ flex: 1, height: "100%", background: color1 }}></div>
      {color2 && <div style={{ flex: 1, height: "100%", background: color2 }}></div>}
    </div>
  );
};

export default BeltColorCircle;
