import { Box, LinearProgress, Typography } from "@mui/joy";
import React from "react";

const StartedGroupItem = ({ finishedGames, allGames }) => {
  const progressPercent = Math.ceil((100 / allGames) * finishedGames);
  return (
    <Box display={"flex"} pt={1.5} px={0.5} alignItems={"center"} gap={1}>
      <Typography level="body3">
        {finishedGames}/{allGames} بازی
      </Typography>
      <LinearProgress determinate value={progressPercent} />
      <Typography level="body3">{progressPercent}%</Typography>
    </Box>
  );
};

export default StartedGroupItem;
