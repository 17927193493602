import { Box, Dropdown, IconButton, Menu, MenuButton, MenuItem, Sheet, Typography } from "@mui/joy";
import React from "react";
import { LuPrinter } from "react-icons/lu";

const GroundContainer = ({
  children,
  ground,
  onGetPdf,
  loadingPdf = false,
  onGetUnknownGamePdf,
}) => {
  return (
    <Sheet
      variant="outlined"
      sx={(theme) => ({
        width: "100%",
        transition: "200ms",
        borderRadius: theme.radius.md,
        boxShadow: theme.shadow.sm,
        background: "var(--joy-palette-background-surface)",
        borderColor: "var(--joy-palette-neutral-outlinedBorder)",
        p: 1.5,
        gap: 2,
      })}
    >
      <Box>
        <Sheet
          display={"flex"}
          sx={{
            px: 2.5,
            py: 1.5,
            borderRadius: "var(--joy-radius-sm)",
            boxShadow: "var(--joy-shadow-sm)",
            display: "flex",
            alignItems: "center",
          }}
          variant="solid"
          color="primary"
        >
          <Typography fontWeight={"bold"} textColor={"common.white"} flex={1}>
            زمین {ground.title}
          </Typography>

          <Dropdown>
            <MenuButton
              loading={loadingPdf}
              slots={{ root: IconButton }}
              slotProps={{ root: { variant: "solid", color: "secondary" } }}
            >
              <LuPrinter />
            </MenuButton>
            <Menu placement="bottom-end">
              <MenuItem onClick={onGetPdf} sx={{ direction: "rtl" }}>
                چاپ همه بازی ها
              </MenuItem>
              <MenuItem onClick={onGetUnknownGamePdf} sx={{ direction: "rtl" }}>
                چاپ بازی های برنده نامشخص
              </MenuItem>
            </Menu>
          </Dropdown>
        </Sheet>
      </Box>
      {children}
    </Sheet>
  );
};

export default GroundContainer;
