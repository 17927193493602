import { Box, Button, Slider } from "@mui/joy";
import React, { useState } from "react";
import ColorSchemeToggle from "./ColorSchemeToggle";
import Dashboard from "./Dashboard";

const SettingsPage = () => {
  const [fontSize, setFontSize] = useState(
    parseFloat(getComputedStyle(document.documentElement).fontSize)
  );
  const handleChangeFontSize = (value) => {
    document.documentElement.style.fontSize = `${value}px`;
    setFontSize(value);
  };
  const handleChangeFontSizeSlider = (e) => {
    handleChangeFontSize(e.target.value);
  };
  return (
    <Dashboard title={"تنظیمات"}>
      <Box
        sx={{
          bgcolor: "background.sheet",
          height: "100%",
          m: 2,
          borderRadius: "md",
          py: 5,
          px: 4,
          display: "flex",
          flexDirection: "column",
          gap: 3,
          boxShadow: "sm",
        }}
      >
        {/* <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <Box>نمایش (روشن / تاریک)</Box>
          <Box>
            <ColorSchemeToggle sx={{ mx: 2 }} />
          </Box>
        </Box> */}
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={2}>
          <Box>اندازه فونت </Box>
          <Box width={200} sx={{ direction: "ltr" }} display={"flex"} alignItems={"center"}>
            <Slider value={fontSize} min={12} max={25} onChange={handleChangeFontSizeSlider} />
          </Box>
          <Button variant="outlined" size={"sm"} onClick={() => handleChangeFontSize(16)}>
            پیش فرض
          </Button>
        </Box>
      </Box>
    </Dashboard>
  );
};

export default SettingsPage;
