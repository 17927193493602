import { Box, Button, Chip, Typography } from "@mui/joy";
import React, { useState } from "react";
import Dashboard from "./components/Dashboard";
import CrudModal, { FORM_TYPES, FormItem } from "./components/GameRoles/CrudModal";
import BeltColorCircle from "./components/common/BeltColorCircle";
import useReq from "./services";

const DEFAULT_CRUD_MODAL_STATE = {
  belt: { status: false, data: null, meta: null },
  weight: { status: false, data: null, meta: null },
  age: { status: false, data: null, meta: null },
};

const GameRoles = () => {
  //get
  const { state: belts, get: getBelts } = useReq("get-belts");
  const { state: ages, get: getAges } = useReq("get-ages");
  const { state: weights, get: getWeights } = useReq("get-weights");

  const [crudModalState, setCrudModalState] = useState(DEFAULT_CRUD_MODAL_STATE);
  //belt endpoints
  const { set: createBelt } = useReq("create-belt");
  const { set: editBelt } = useReq("edit-belt");
  const { get: deleteBelt } = useReq("delete-belt");
  //age endpoints
  const { set: createAge } = useReq("create-age");
  const { set: editAge } = useReq("edit-age");
  const { get: deleteAge } = useReq("delete-age");
  //wight endpoints
  const { set: createWeight } = useReq("create-weight");
  const { set: editWeight } = useReq("edit-weight");
  const { get: deleteWeight } = useReq("delete-weight");

  const handleOpenCrudModal = (type, data = null, meta = null) => {
    setCrudModalState({ ...crudModalState, [type]: { status: true, data, meta } });
  };
  const handleCloseCrudModal = () => {
    setCrudModalState(DEFAULT_CRUD_MODAL_STATE);
  };

  /////// belt handlers //////
  const handleCreateBelt = (data) => {
    createBelt(data)
      .then(() => {
        handleCloseCrudModal();
        getBelts();
      })
      .catch(() => {});
  };
  const handleEditBelt = (data) => {
    editBelt(data, [data.id])
      .then(() => {
        handleCloseCrudModal();
        getBelts();
      })
      .catch(() => {});
  };
  const handleDeleteBelt = (data) => {
    deleteBelt([data.id])
      .then(() => {
        handleCloseCrudModal();
        getBelts();
      })
      .catch(() => {});
  };
  /////// age handlers //////
  const handleCreateAge = (data) => {
    createAge(data)
      .then(() => {
        handleCloseCrudModal();
        getAges();
      })
      .catch(() => {});
  };
  const handleEditAge = (data) => {
    editAge(data, [data.id])
      .then(() => {
        handleCloseCrudModal();
        getAges();
      })
      .catch(() => {});
  };
  const handleDeleteAge = (data) => {
    deleteAge([data.id])
      .then(() => {
        handleCloseCrudModal();
        getAges();
      })
      .catch(() => {});
  };

  /////// weight handlers //////
  const handleCreateWeight = (data) => {
    createWeight({ ...data, age: crudModalState.weight.meta.id })
      .then(() => {
        handleCloseCrudModal();
        getWeights();
      })
      .catch(() => {});
  };
  const handleEditWeight = (data) => {
    editWeight(data, [data.id])
      .then(() => {
        handleCloseCrudModal();
        getWeights();
      })
      .catch(() => {});
  };
  const handleDeleteWeight = (data) => {
    deleteWeight([data.id])
      .then(() => {
        handleCloseCrudModal();
        getWeights();
      })
      .catch(() => {});
  };

  return (
    <Dashboard title={"قوانین مسابقه"}>
      <Box sx={{ width: "100%", height: "100%", p: 2 }}>
        <Box
          sx={{
            bgcolor: "background.sheet",
            width: "100%",
            borderRadius: "md",
            boxShadow: "sm",
            p: 5,
          }}
        >
          <Box
            sx={{
              borderRadius: "md",
              px: 2,
              py: 2,
              border: "1px solid",
              borderColor: "neutral.100",
            }}
          >
            <Box
              sx={{
                borderBottom: "1px solid",
                borderColor: "neutral.100",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                pb: 2,
                px: 2,
              }}
            >
              <Typography>کمربند ها</Typography>
              <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"}>
                <Button size={"sm"} onClick={() => handleOpenCrudModal("belt")}>
                  افزودن کمربند{" "}
                </Button>
              </Box>
            </Box>
            <Box
              sx={{ pt: 2, display: "flex", flexDirection: "row", gap: 1.4, alignItems: "center" }}
            >
              {belts &&
                belts.map((b) => (
                  <Chip
                    onClick={() => handleOpenCrudModal("belt", b)}
                    variant="soft"
                    sx={{ pr: 1.8, pl: 1.4 }}
                    startDecorator={
                      <BeltColorCircle
                        style={{
                          marginRight: "-0.5rem",
                          height: ".8rem",
                        }}
                        color={b.color}
                      />
                    }
                  >
                    {b.title}
                  </Chip>
                ))}
            </Box>
          </Box>
          <Box
            sx={{
              mt: 2,
              borderRadius: "md",
              px: 2,
              py: 2,
              border: "1px solid",
              borderColor: "neutral.100",
            }}
          >
            <Box
              sx={{
                borderBottom: "1px solid",
                borderColor: "neutral.100",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                pb: 2,
                px: 2,
              }}
            >
              <Typography> رده سنی</Typography>
              <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"}>
                <Button size={"sm"} onClick={() => handleOpenCrudModal("age")}>
                  افزودن رده سنی
                </Button>
              </Box>
            </Box>
            {ages &&
              ages.map((a, i) => (
                <Box
                  sx={{
                    borderBottom: "1px solid",
                    p: 1,
                    borderColor: "neutral.100",
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                  key={i}
                >
                  <Box flex={1}>{a.title}</Box>
                  <Box flex={1}>{a.sex === "MAN" ? "مردانه" : "زنانه"}</Box>
                  <Box
                    flex={2}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      {a.year}/{a.month}/{a.day}
                    </div>
                    <Box
                      sx={{
                        flex: 1,
                        border: "1px dashed var(--joy-palette-neutral-400)",
                        height: "1px",
                        mx: 1,
                      }}
                    >
                      {" "}
                    </Box>
                    <Box
                      sx={{
                        bgcolor: "primary.100",
                        px: 2,
                        borderRadius: "sm",
                        fontSize: "sm",
                        display: "flex",
                        alignItems: "center",
                        paddingTop: "0.25rem",
                      }}
                    >
                      {a.gap}
                    </Box>
                  </Box>
                  <Box sx={{ pl: 3, borderLeft: "1px solid var(--joy-palette-neutral-200)" }}>
                    <Button onClick={() => handleOpenCrudModal("age", a)} size="sm">
                      ویرایش
                    </Button>
                  </Box>

                  <Box flex={5} sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                    {weights
                      .filter((w) => w.age === a.id)
                      .map((w, i) => (
                        <Chip
                          size="sm"
                          onClick={() => handleOpenCrudModal("weight", w)}
                          sx={{ direction: "ltr" }}
                          variant="soft"
                        >
                          {w.title}
                        </Chip>
                      ))}
                    <Chip
                      onClick={() => handleOpenCrudModal("weight", null, a)}
                      size="sm"
                      color="primary"
                      variant="solid"
                    >
                      افزودن وزن
                    </Chip>
                  </Box>
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
      <CrudModal
        width={200}
        form={{
          items: [
            new FormItem("title", FORM_TYPES.string, { placeholder: "عنوان" }),
            new FormItem("color", FORM_TYPES.color, {
              placeholder: "رنگ",
              sx: { direction: "ltr" },
            }),
          ],
          createTitle: "ایجاد",
          editTitle: "ویرایش",
          onCreate: handleCreateBelt,
          onEdit: handleEditBelt,
          onDelete: handleDeleteBelt,
        }}
        anchor={crudModalState.belt}
        onClose={handleCloseCrudModal}
      />
      <CrudModal
        width={200}
        form={{
          items: [
            new FormItem("title", FORM_TYPES.string, { placeholder: "عنوان" }),
            new FormItem("sex", FORM_TYPES.select, {
              placeholder: "جنسیت",
              items: [
                { key: "MAN", title: "مردانه" },
                { key: "WOMAN", title: "زنانه" },
              ],
            }),
            new FormItem("year", FORM_TYPES.string, { placeholder: "سال" }),
            new FormItem("month", FORM_TYPES.string, { placeholder: "ماه" }),
            new FormItem("day", FORM_TYPES.string, { placeholder: "روز" }),
            new FormItem("gap", FORM_TYPES.string, { placeholder: "فاصله سنی" }),
          ],
          createTitle: "ایجاد",
          editTitle: "ویرایش",
          onCreate: handleCreateAge,
          onEdit: handleEditAge,
          onDelete: handleDeleteAge,
        }}
        anchor={crudModalState.age}
        onClose={handleCloseCrudModal}
      />
      <CrudModal
        width={200}
        form={{
          items: [
            new FormItem("title", FORM_TYPES.string, { placeholder: "عنوان" }),
            new FormItem("weight", FORM_TYPES.string, { placeholder: "وزن" }),
            new FormItem("from_weight", FORM_TYPES.string, { placeholder: "از وزن" }),
            new FormItem("to_weight", FORM_TYPES.string, { placeholder: "تا وزن" }),
          ],
          createTitle: "ایجاد",
          editTitle: "ویرایش",
          onCreate: handleCreateWeight,
          onEdit: handleEditWeight,
          onDelete: handleDeleteWeight,
        }}
        anchor={crudModalState.weight}
        onClose={handleCloseCrudModal}
      />
    </Dashboard>
  );
};

export default GameRoles;
