import { Box } from "@mui/joy";
import React from "react";

const PublicLayout = ({ children }) => {
  return (
    <Box
      sx={{
        height: { sx: "auto", md: "100vh" },
        width: { sx: "auto", md: "100vw" },
        display: "flex",
        flexDirection: "column",
      }}
    >
      {children}
    </Box>
  );
};

export default PublicLayout;
