export const checkFillAll = (obj, configs) => {
  return [...Object.entries(obj)].every(([key, value]) => {
    const isIgnored = configs.ignoredItems && configs.ignoredItems.findIndex((k) => k === key) > -1;

    if (!isIgnored) {
      if (!!value) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  });
};
