import { Box, Chip } from "@mui/joy";
import React from "react";
import BeltColorCircle from "./BeltColorCircle";

export const GroupTitleLG = ({ group, startDecorator, endDecorator }) => {
  if (!group) return null;
  return (
    <Box sx={{ display: "flex", gap: 2 }}>
      {startDecorator}
      <Chip>{group && group.age.title}</Chip>
      {group && (
        <Chip
          startDecorator={<BeltColorCircle color={group.belt.color} style={{ height: "1rem" }} />}
          slotProps={{ startDecorator: { sx: { ml: 1 } } }}
        >
          {group.belt.title}
        </Chip>
      )}
      <Chip>وزن {group && group.weight.title}</Chip>
      <Chip>{group && group.ground.title}</Chip>
      {endDecorator}
    </Box>
  );
};

export const GroupTitleSM = ({ group }) => {
  if (!group) return null;
  return (
    <Box sx={{ display: "flex", gap: 2 }}>
      <Chip>{group.age.title}</Chip>

      <Chip
        startDecorator={<BeltColorCircle color={group.belt.color} style={{ height: "1rem" }} />}
        slotProps={{ startDecorator: { sx: { ml: 1 } } }}
      >
        {group.belt.title}
      </Chip>

      <Chip>وزن {group.weight.title}</Chip>
    </Box>
  );
};
