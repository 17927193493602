import { Box, Button, CircularProgress } from "@mui/joy";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ReverseTree from "../../components/GroupGames/ReverseTree";
import ScaleButtons from "../../components/GroupGames/ScaleButtons";
import PublicHeader from "../../components/shared/PublicHeader";
import useReq from "../../services";
import BeltColorCircle from "../../components/common/BeltColorCircle";
import { TbReload } from "react-icons/tb";

const PublicGames = () => {
  const reverseTreeRef = useRef();
  const [scale, setScale] = useState(1);
  const [games, setGame] = useState([]);
  const [group, setGroup] = useState(null);
  const { get: getGames, loading } = useReq("get-games-by-group");
  const { id } = useParams();

  const handleGetData = useCallback(() => {
    getGames([id])
      .then((data) => {
        const { group, games } = data.data;
        setGame(games);
        setGroup(group);
      })
      .catch((err) => console.log(err));
  }, [getGames, id]);

  useEffect(() => {
    handleGetData();
  }, [handleGetData]);

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <PublicHeader />
      <Box
        sx={{
          bgcolor: "",
          borderBottom: "1px solid",
          borderBottomColor: "neutral.200",
          py: 1,
          px: 2,
          display: "flex",
          alignItems: "center",
          gap: { xs: 1, md: 3 },
          fontSize: { xs: "sm", md: "md" },
        }}
      >
        <Box>{group && group.age.title}</Box>
        <Box display={"flex"} alignItems={"center"} gap={1}>
          {group && <BeltColorCircle color={group.belt.color} style={{ height: "1.2rem" }} />}
          <Box>{group && group.belt.title}</Box>
        </Box>
        <Box>وزن {group && group.weight.title}</Box>
        <Box>{group && group.ground.title}</Box>
        <Box flex={1} display={"flex"} flexDirection={"row-reverse"}>
          <Button
            onClick={handleGetData}
            slotProps={{ startDecorator: { sx: { mr: 0, ml: "0.5rem" } } }}
            startDecorator={<TbReload />}
            variant="outlined"
          >
            بارگیری مجدد
          </Button>
        </Box>
      </Box>
      <Box
        sx={{ flex: 1, display: "flex", position: "relative", flexDirection: "row", width: "100%" }}
      >
        <Box sx={{ position: "absolute", inset: 0 }}>
          <Box display={"flex"} flexDirection={"row"} height={"100%"}>
            <Box
              flex={1}
              position={"relative"}
              height={"100%"}
              overflow={"auto"}
              sx={{ bgcolor: "neutral.100" }}
            >
              <ReverseTree
                ref={reverseTreeRef}
                games={games}
                scale={scale}
                onOpenGameDetail={() => {}}
              />
            </Box>

            <ScaleButtons
              scale={scale}
              setScale={setScale}
              position={"fixed"}
              left={30}
              bottom={20}
            />
          </Box>
        </Box>
      </Box>
      {loading && (
        <Box
          sx={{
            zIndex: 2,
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#ffffffaa",
          }}
        >
          <CircularProgress size={"lg"} />
        </Box>
      )}
    </Box>
  );
};

export default PublicGames;
