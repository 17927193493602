import React, { useState } from "react";
import useReq from "../../services";
import { checkFillAll } from "../../utils/validation";

const DEFAULT_PLAYER_STATE = {
  id: null,
  name: "",
  age: "",
  weight: "",
  belt: "",
  coach: "",
};

export const FORM_SUBMIT_OPTIONS = {
  createAndNew: 1,
  createAndContinue: 2,
};

const usePlayerForm = (event) => {
  const { state: coaches, set: getCoaches, setState: setCoaches } = useReq("get-coaches");
  const { set: createCoach, loading: createCoachLoading } = useReq("create-coach");
  const { state: ages } = useReq("get-ages");
  const { state: weights } = useReq("get-weights");
  const { set: createPlayer, loading: createPlayerLoading } = useReq("create-player");
  const { set: editPlayer, loading: editPlayerLoading } = useReq("edit-player");
  const { set: removePlayer, loading: removePlayerLoading } = useReq("remove-player");
  const [playerState, setPlayerState] = useState(DEFAULT_PLAYER_STATE);
  const [formSubmitOption, setFormSubmitOption] = useState(FORM_SUBMIT_OPTIONS.createAndNew);

  const [openCreateCoachModal, setOpenCreateCoachModal] = React.useState(null);

  React.useEffect(() => {
    if (event && event.id) {
      getCoaches({ event_id: event.id });
    }
  }, [event, getCoaches]);

  const handleChangeState = (key, value) => {
    setPlayerState((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const handleOpenCreateCoachModal = (index) => () => setOpenCreateCoachModal(index);
  const handleCloseCreateCoachModal = () => setOpenCreateCoachModal(null);

  const handleAddCoach = (coach) => {
    if (!event || event.id === null) return;
    createCoach({
      event: parseInt(event.id),
      club: coach.clubId,
      name: coach.name,
    }).then((data) => {
      setCoaches([...coaches, data]);
      handleChangeState("coach", data.id);
      handleCloseCreateCoachModal();
    });
  };
  const handleCreatePlayer = () => {
    if (checkFillAll(playerState, { ignoredItems: ["id"] })) {
      return createPlayer({ event: event.id, ...playerState });
    } else {
      return new Promise(() => {
        throw new Error("مقادیر را وارد نمایید");
      });
    }
  };
  const handleSetPlayerAsEdit = (player) => {
    setPlayerState({
      id: player.id,
      name: player.name,
      belt: player.belt.id,
      weight: player.weight.id,
      age: player.age.id,
      coach: player.coach.id,
    });
  };
  const handleClearPlayerState = () => {
    setPlayerState(DEFAULT_PLAYER_STATE);
  };
  const handleEditPlayer = () => {
    if (checkFillAll(playerState, { ignoredItems: ["id"] })) {
      return editPlayer({ event: event.id, ...playerState }, [playerState.id]);
    } else {
      return new Promise(() => {
        throw new Error("مقادیر را وارد نمایید");
      });
    }
  };
  const handleRemovePlayer = (id) => {
    return removePlayer({}, [id]);
  };

  const weightsByAge = weights.filter((w) => w.age === playerState.age);
  // TODO: add this into the backend and remove get event in index.js file
  const agesBySex = ages.filter((a) => (event && event.sex && a.sex === event.sex ? a : null));
  return {
    createCoachLoading,
    coaches,
    handleAddCoach,
    handleOpenCreateCoachModal,
    handleCloseCreateCoachModal,
    handleChangeState,
    playerState,
    agesBySex,
    weightsByAge,
    openCreateCoachModal,
    handleCreatePlayer,
    createPlayerLoading,
    handleSetPlayerAsEdit,
    handleClearPlayerState,
    handleEditPlayer,
    editPlayerLoading,
    setFormSubmitOption,
    formSubmitOption,
    handleRemovePlayer,
    removePlayerLoading,
  };
};

export default usePlayerForm;
