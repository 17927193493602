import * as React from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import useReq from "../../services";
import Dashboard from "../Dashboard";
import Layout from "../Layout";
import PlayerForm from "./PlayerForm";
import Table from "./Table";
import usePlayerForm, { FORM_SUBMIT_OPTIONS } from "./usePlayerForm";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Members() {
  const { set: getPlayers, loading: playersLoading } = useReq("get-players");
  const { state: event, get: getEvent } = useReq("get-event-by-id");
  const playerForm = usePlayerForm(event);
  const [players, setPlayers] = React.useState([]);

  const query = useQuery();
  const eid = parseInt(query.get("event_id"));
  const handleGetPlayers = React.useCallback(
    () => getPlayers({ event_id: eid }).then((data) => setPlayers(data)),
    [getPlayers, eid]
  );
  React.useEffect(() => {
    if (eid) {
      handleGetPlayers(eid);
      getEvent([eid]);
    }
  }, [handleGetPlayers, eid, getEvent]);

  const handleCreate = () => {
    playerForm
      .handleCreatePlayer()
      .then(() => {
        if (playerForm.formSubmitOption === FORM_SUBMIT_OPTIONS.createAndNew) {
          playerForm.handleClearPlayerState();
        }
        toast.success("ایجاد شد");
        handleGetPlayers();
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.code) {
          return toast.error("این گروه درحال مسابقه دادن میباشد");
        }
        if (err.message) {
          return toast.error(err.message);
        }
      });
  };
  const handleEdit = () => {
    playerForm
      .handleEditPlayer()
      .then((data) => {
        playerForm.handleClearPlayerState();
        handleGetPlayers();
        toast.success("ویرایش شد");
      })
      .catch((err) => {
        if (err.message) {
          return toast.error(err.message);
        }
      });
  };
  const handleRemove = (id) => {
    playerForm
      .handleRemovePlayer(id)
      .then(() => {
        toast.success("حذف شد");
        playerForm.handleClearPlayerState();
        handleGetPlayers();
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.code === 1043) {
          return toast.error("بازی های این بازیکن شروع شده است.");
        }
      });
  };

  return (
    <Dashboard title={"مبارزان"}>
      <Layout
        sx={{ display: "flex", flexDirection: "column" }}
        loading={playersLoading || playerForm.createPlayerLoading}
      >
        <PlayerForm
          onEdit={handleEdit}
          event={event}
          playerForm={playerForm}
          onCreate={handleCreate}
        />
        <Table
          onEdit={playerForm.handleSetPlayerAsEdit}
          onRemove={handleRemove}
          players={players}
          editedId={playerForm.playerState && playerForm.playerState.id}
        />
      </Layout>
    </Dashboard>
  );
}
