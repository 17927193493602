export class Now {
  now;
  constructor() {
    this.now = new Date();
  }

  dateTime() {
    const offset = this.now.getTimezoneOffset();
    const date = new Date(this.now.getTime() - offset * 60 * 1000);

    return {
      toString: () => {
        return date
          .toISOString()
          .replace(/\.(\w+)/i, "")
          .replace(/(:|T)/g, "-");
      },
    };
  }
}
