import html2canvas from "html2canvas";

const capture = async (element, name = "image") => {
  const canvas = await html2canvas(element);
  const dataUrl = canvas.toDataURL("image/png");
  const link = document.createElement("a");
  link.href = dataUrl;
  link.download = `${name}.png`;
  link.click();
};

export { capture };
