import { useCallback, useState } from "react";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return [result, removed];
};

const useGroundList = () => {
  const [groundsList, setGroundsList] = useState({
    lists: [],
  });
  const getList = (id) => groundsList.lists[id]["groups"];

  const handleDragEnd = (result, callback) => {
    const { source, destination } = result;
    if (!destination) return;
    if (source.droppableId === destination.droppableId) {
      const items = reorder(getList(source.droppableId), source.index, destination.index);
      let newList = groundsList.lists;
      newList[source.droppableId]["groups"] = items;
      setGroundsList({ lists: [...newList] });
    } else {
      const [result] = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );
      setGroundsList((prevState) => {
        let newList = prevState.lists;
        newList[source.droppableId]["groups"] = result[source.droppableId];
        newList[destination.droppableId]["groups"] = result[destination.droppableId];
        callback(
          newList[destination.droppableId]["groups"][destination.index],
          newList[destination.droppableId].id,
          newList[source.droppableId].id
        );
        return { ...prevState, list: [...newList] };
      });
    }
  };
  const handleInitialList = useCallback((grounds, unknownAssignedGroups) => {
    setGroundsList({
      lists: [
        ...grounds.sort((a, b) => a.id - b.id),
        {
          started: false,
          disableStartButton: true,
          groups: unknownAssignedGroups,
        },
      ],
    });
  }, []);
  return {
    groundsList,
    onDragEndGroundList: handleDragEnd,
    onInitialGroundList: handleInitialList,
  };
};

export default useGroundList;
