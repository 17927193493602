import { extendTheme } from "@mui/joy/styles";

export default extendTheme({
  direction: "rtl",
  colorSchemes: {
    light: {
      palette: {
        // primary: common,
        red: {
          solid: "#f43f5e",
        },
        secondary: {
          // Credit:
          // https://github.com/tailwindlabs/tailwindcss/blob/master/src/public/colors.js
          // 50: "#fff1f2",
          // 100: "#ffe4e6",
          // 200: "#fecdd3",
          // 300: "#fda4af",
          // 400: "#fb7185",
          // 500: "#f43f5e",
          // 600: "#e11d48",
          // 700: "#be123c",
          // 800: "#9f1239",
          // 900: "#881337",
          // 950: "#4c0519",
          50: "#f0fdfa",
          100: "#ccfbf1",
          200: "#99f6e4",
          300: "#5eead4",
          400: "#2dd4bf",
          500: "#14b8a6",
          600: "#0d9488",
          700: "#0f766e",
          800: "#115e59",
          900: "#134e4a",
          950: "#042f2e",
          solidBg: "var(--joy-palette-secondary-400)",
          solidActiveBg: "var(--joy-palette-secondary-500)",
          outlinedBorder: "var(--joy-palette-secondary-500)",
          outlinedColor: "var(--joy-palette-secondary-700)",
          outlinedActiveBg: "var(--joy-palette-secondary-100)",
          softColor: "var(--joy-palette-secondary-800)",
          softBg: "var(--joy-palette-secondary-200)",
          softActiveBg: "var(--joy-palette-secondary-300)",
          plainColor: "var(--joy-palette-secondary-700)",
          plainActiveBg: "var(--joy-palette-secondary-100)",
        },
        background: {
          body: "var(--joy-palette-neutral-50)",
          sheet: "var(--joy-palette-background-surface)",
        },
      },
    },
    dark: {
      palette: {
        neutral: {
          outlinedBorder: "var(--joy-palette-neutral-700)",
        },
      },
    },
  },
  components: {
    JoyAutocomplete: {
      styleOverrides: {
        root: {
          boxShadow: "var(--joy-shadow-xs)",
        },
      },
    },
    JoyButton: {
      styleOverrides: {
        root: {
          boxShadow: "var(--joy-shadow-xs)",
        },
      },
    },
    JoyInput: {
      styleOverrides: {
        root: {
          boxShadow: "var(--joy-shadow-xs)",
        },
      },
    },
    JoyTextarea: {
      styleOverrides: {
        root: {
          boxShadow: "var(--joy-shadow-xs)",
        },
      },
    },
    JoySelect: {
      styleOverrides: {
        root: {
          boxShadow: "var(--joy-shadow-xs)",
        },
      },
    },
  },
  fontFamily: {
    display: "Vazirmatn, var(--joy-fontFamily-fallback)",
    body: "Vazirmatn, var(--joy-fontFamily-fallback)",
  },
});
