import { CssBaseline, CssVarsProvider, GlobalStyles, StyledEngineProvider } from "@mui/joy";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import GameRoles from "./GameRoles";
import CreateEvent from "./components/CreateEvent";
import EditEvent from "./components/EditEvent";
import EventDetail from "./components/EventDetail";
import Events from "./components/Events";
import GameFilter from "./components/Filters/GameFilter";
import MemberFilter from "./components/Filters/MemberFilter";
import GroupMatches from "./components/GroupGames";
import GroupOfGround from "./components/GroupOfGround";
import GroupPlayers from "./components/GroupPlayers";
import LoginPage from "./components/Login";
import Members from "./components/Members";
import ProtectedRoute from "./components/ProtectedRoute";
import SettingsPage from "./components/SettingsPage";
import PublicHomePage from "./pages/public/home.page";
import customTheme from "./utils/theme";
import PublicGames from "./pages/public/games.page";

const routes = [
  { path: "/dashboard/", element: <Events /> },
  { path: "/dashboard/create-event", element: <CreateEvent /> },
  { path: "/dashboard/members", element: <Members /> },
  { path: "/dashboard/groups/:eid", element: <GroupPlayers /> },
  { path: "/dashboard/event-detail/:id", element: <EventDetail /> },
  { path: "/dashboard/grounds/:eid", element: <GroupOfGround /> },
  { path: "/dashboard/group-games/:id", element: <GroupMatches /> },
  { path: "/dashboard/edit-event/:id", element: <EditEvent /> },
  { path: "/dashboard/settings", element: <SettingsPage /> },
  { path: "/dashboard/game-roles", element: <GameRoles /> },
  { path: "/dashboard/game-filter", element: <GameFilter /> },
  { path: "/dashboard/member-filter", element: <MemberFilter /> },
  { path: "/", element: <PublicHomePage />, protectLess: true },
  { path: "/games/:id", element: <PublicGames />, protectLess: true },
  { path: "/login", element: <LoginPage />, protectLess: true },
];

function App() {
  return (
    <div style={{ direction: "rtl" }}>
      <StyledEngineProvider injectFirst>
        <CssVarsProvider disableTransitionOnChange theme={customTheme}>
          <GlobalStyles
            styles={{
              "[data-feather], .feather": {
                color: "var(--Icon-color)",
                margin: "var(--Icon-margin)",
                fontSize: "var(--Icon-fontSize, 20px)",
                width: "1em",
                height: "1em",
              },
            }}
          />
          <CssBaseline />
          <BrowserRouter>
            <Routes>
              {routes.map((r, index) => (
                <Route
                  key={index}
                  exact
                  path={r.path}
                  element={r.protectLess ? r.element : <ProtectedRoute>{r.element}</ProtectedRoute>}
                  // element={r.protectLess ? <ProtectedRoute>{r.element}</ProtectedRoute> : r.element}
                />
              ))}
            </Routes>
          </BrowserRouter>
        </CssVarsProvider>
      </StyledEngineProvider>
    </div>
  );
}

export default App;
