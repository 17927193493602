import { Box, CircularProgress } from "@mui/joy";

const Layout = ({ children, loading = false, sx = {}, ...props }) => {
  return (
    <Box
      sx={{
        position: "relative",
        px: {
          xs: 1.5,
          md: 2,
        },
        pt: 2,
        pb: {
          xs: 1,
          sm: 1,
          md: 2,
        },
        flex: "100%",
        ...sx,
      }}
      {...props}
    >
      {loading && (
        <Box
          sx={{
            zIndex: 2,
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#ffffffaa",
          }}
        >
          <CircularProgress size={"lg"} />
        </Box>
      )}
      {children}
    </Box>
  );
};

export default Layout;
