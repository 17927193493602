import { Box, Button, Typography } from "@mui/joy";
import React from "react";
import { LuInfo } from "react-icons/lu";
import { MdOutlineSportsMartialArts } from "react-icons/md";
import { Link } from "react-router-dom";

const PublicHeader = () => {
  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 10000000,
        background: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: 1,
        borderBottom: "1px solid var(--joy-palette-neutral-200)",
      }}
    >
      <Box display={"flex"} alignItems={"center"} gap={5}>
        <Box display={"flex"} alignItems={"center"} gap={2}>
          <MdOutlineSportsMartialArts color={"var(--joy-palette-primary-softColor)"} size={32} />
          <Typography fontWeight={"bold"} fontSize={20}>
            تکواندو من
          </Typography>
        </Box>
        {/* <Box
          alignItems={"center"}
          gap={1}
          color={"var(--joy-palette-neutral-500)"}
          sx={{
            display: {
              xs: "none",
              md: "flex",
            },
          }}
        >
          <LuInfo size={17} />
          <Typography level="body-sm">roles</Typography>
        </Box> */}
      </Box>

      <Link to={"/login"}>
        <Button size="sm" sx={{ px: 5 }}>
          ورود
        </Button>
      </Link>
    </Box>
  );
};

export default PublicHeader;
