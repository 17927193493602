import { Box, CircularProgress } from "@mui/joy";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useReq from "../../services";
import { capture } from "../../utils/capture";
import { Now } from "../../utils/time";
import { GroupTitleLG } from "../common/GroupTitle";
import Dashboard from "../Dashboard";
import GameModal from "../Modals/GameModal";
import GameItem from "./GameItem";
import ReverseTree from "./ReverseTree";
import ScaleButtons from "./ScaleButtons";
import ScreenshotButton from "./ScreenshotButton";

const GroupMatches = () => {
  const reverseTreeRef = useRef();
  const [scale, setScale] = useState(1);
  const [games, setGame] = useState([]);
  const [group, setGroup] = useState(null);
  const { get: getGames, loading } = useReq("get-games-by-group-expand");
  const { id } = useParams();
  const [selectedGame, setSelectedGame] = useState(null);

  const handleGetGames = useCallback(() => {
    getGames([id])
      .then((data) => {
        const { group, games } = data.data;
        setGame(games);
        setGroup(group);
      })
      .catch((err) => console.log(err));
  }, [getGames, id]);

  useEffect(() => {
    handleGetGames();
  }, [handleGetGames]);

  const handleOpenGameDetail = (game) => {
    setSelectedGame(game);
  };
  const handleCloseGameDetail = () => {
    setSelectedGame(null);
  };
  const handleCloseModalAndReset = () => {
    handleCloseGameDetail();
    handleGetGames();
  };

  const handleCaptureFromReverseTree = () => {
    const now = new Now();
    const captureImageName = `chart-${now.dateTime().toString()}`;
    capture(reverseTreeRef.current, captureImageName);
  };

  return (
    <Dashboard
      title={
        <GroupTitleLG
          group={group}
          startDecorator={<Box>بازی های گروه</Box>}
          endDecorator={loading && <CircularProgress size={"sm"} />}
        />
      }
    >
      <Box display={"flex"} flexDirection={"row"} height={"100%"}>
        <Box
          flex={1}
          position={"relative"}
          height={"100%"}
          overflow={"auto"}
          sx={{ bgcolor: "neutral.100" }}
        >
          <ReverseTree
            ref={reverseTreeRef}
            games={games}
            scale={scale}
            onOpenGameDetail={(game) => handleOpenGameDetail(game)}
          />
        </Box>
        <Box width={350} height={"100%"} position={"relative"} bgcolor={"background.surface"}>
          <div style={{ position: "absolute", inset: 0, overflowY: "auto" }}>
            {games.map((game, index) => {
              if (game && game.player_one && game.player_two) {
                return (
                  <GameItem
                    key={index}
                    game={game}
                    onOpenGameDetail={() => handleOpenGameDetail(game)}
                  />
                );
              }
              return null;
            })}
          </div>
        </Box>
        <ScaleButtons scale={scale} setScale={setScale} left={375} top={5} position={"absolute"} />
        <ScreenshotButton onCapture={handleCaptureFromReverseTree} />
        <GameModal
          game={selectedGame}
          onChange={handleCloseModalAndReset}
          onClose={handleCloseGameDetail}
        />
      </Box>
    </Dashboard>
  );
};

export default GroupMatches;
