import { Box, Sheet, Typography } from "@mui/joy";
import React, { forwardRef, useMemo } from "react";

const blueColor = "primary.600";
const redColor = "red.solid";

const createPlayerClass = (obj) => {
  if (!obj) return null;
  return `player-cls-${obj.id}`;
};

const Player = ({ player = { name: "" }, color, classname }) => {
  if (!player) {
    player = { name: "" };
  }
  return (
    <Sheet
      sx={{
        color: "black",
        width: 200,
        maxWidth: 200,
        minWidth: 200,
        textAlign: "right",
        px: 2,
        p: 1,
        position: "relative",
        my: 2,
        border: "1px solid",
        borderColor: "neutral.100",
      }}
      className={classname}
    >
      <Typography visibility={!player.name && "hidden"}>
        {player.name ? player.name : "unknown"}
      </Typography>
      <Box
        position={"absolute"}
        sx={{ width: 20, height: "100%", left: 0, top: 0, bgcolor: color }}
      ></Box>
    </Sheet>
  );
};

const Game = ({
  game = {
    winner: "UNKNOWN",
    id: null,
    ref_one: null,
    ref_two: null,
    player_one: null,
    player_two: null,
    level: 0,
  },
  gamesObj = {},
  color = "",
  onOpenGameDetail,
}) => {
  const winnerPlayer =
    game.winner === "ONE"
      ? game.player_one
      : game.winner === "TWO"
      ? game.player_two
      : { name: "" };

  return (
    <Box
      sx={{
        direction: "ltr",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        position: "relative",
        //   paddingLeft: 2,
      }}
    >
      <Player player={winnerPlayer} color={color} classname={createPlayerClass(game)} />
      <Box
        sx={{
          display: game.level !== -1 ? "flex" : "none",
          flexDirection: "column",
          py: 1,
          position: "relative",
          justifyContent: "center",
          paddingLeft: 4,
        }}
      >
        {game.ref_one ? (
          <Game
            game={gamesObj[game.ref_one]}
            color={blueColor}
            onOpenGameDetail={onOpenGameDetail}
            gamesObj={gamesObj}
          />
        ) : (
          <Player
            player={game.player_one}
            color={blueColor}
            classname={createPlayerClass(game.player_one)}
          />
        )}
        {game.ref_two ? (
          <Game
            game={gamesObj[game.ref_two]}
            color={redColor}
            onOpenGameDetail={onOpenGameDetail}
            gamesObj={gamesObj}
          />
        ) : (
          <Player
            player={game.player_two}
            color={redColor}
            classname={createPlayerClass(game.player_two)}
          />
        )}
        <Box
          onClick={() => onOpenGameDetail(game)}
          sx={{
            position: "absolute",
            left: 100,
            top: "calc(50% - 9px)",
            width: 80,
            height: 18,
            textAlign: "center",
            borderRadius: "lg",
            bgcolor: "neutral.200",
            color: "neutral.500",
            ":hover": {
              bgcolor: "secondary.600",
              cursor: "pointer",
              color: "white",
            },
            fontSize: 11.5,
          }}
        >
          بازی {game.number}
        </Box>
        <div
          style={{
            width: 1,
            background: "black",
            position: "absolute",
            left: "1rem",
            top: "25%",
            bottom: "25%",
          }}
        />
        <div
          style={{
            width: "1em",
            height: 1,
            background: "black",
            position: "absolute",
            left: "1em",
            top: "25%",
          }}
        />
        <div
          style={{
            width: "1em",
            height: 1,
            background: "black",
            position: "absolute",
            left: "1em",
            bottom: "25%",
          }}
        />
        <div
          style={{
            width: "1em",
            height: 1,
            background: "black",
            position: "absolute",
            left: "0em",
            bottom: "50%",
          }}
        />
      </Box>
    </Box>
  );
};

const Line = () => {
  return (
    <div style={{ position: "absolute", left: 0, height: "100%" }}>
      <div
        style={{
          width: 1,
          background: "black",
          position: "absolute",
          left: "-1rem",
          top: "25%",
          bottom: "25%",
        }}
      />
      <div
        style={{
          width: "1em",
          height: 1,
          background: "black",
          position: "absolute",
          left: "-1em",
          top: "25%",
        }}
      />
      <div
        style={{
          width: "1em",
          height: 1,
          background: "black",
          position: "absolute",
          left: "-1em",
          bottom: "25%",
        }}
      />
    </div>
  );
};

const PrevGame = ({ games, onOpenGameDetail }) => {
  return (
    <Box pl={6} py={3} display={"flex"} flexDirection={"column"}>
      <Box display={"flex"} flexDirection={"column"} gap={1}>
        {games.map((g, index) => {
          const className = `prev-game-${g.id}`;
          return (
            <Box
              key={index}
              display={"flex"}
              flexDirection={"column"}
              gap={0.1}
              classname={className}
              position={"relative"}
            >
              <Player player={g.player_one} color={blueColor} />
              <Box
                onClick={() => onOpenGameDetail(g)}
                sx={{
                  position: "absolute",
                  left: 100,
                  top: "calc(50% - 9px)",
                  width: 80,
                  height: 18,
                  textAlign: "center",
                  borderRadius: "lg",
                  bgcolor: "neutral.200",
                  color: "neutral.500",
                  ":hover": {
                    bgcolor: "secondary.600",
                    cursor: "pointer",
                    color: "white",
                  },
                  fontSize: 11.5,
                }}
              >
                بازی {g.number}
              </Box>
              <Player player={g.player_two} color={redColor} />
              <Line />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

const ReverseTree = forwardRef(({ games = [], scale = 1, onOpenGameDetail }, ref) => {
  const finalGame = React.useMemo(() => {
    return games.find((g) => g.reverse_level === 1);
  }, [games]);

  const gamesObj = React.useMemo(() => {
    return games.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.id]: curr,
      };
    }, {});
  }, [games]);

  const prevGames = useMemo(() => {
    return games.reduce((acc, curr) => {
      if (curr.level === -1) {
        const newCurr = curr;
        return [...acc, newCurr];
      }
      return acc;
    }, []);
  }, [games]);

  return (
    <Box
      px={3}
      py={1}
      position={"absolute"}
      display={"flex"}
      style={{ transform: `scale(${scale})`, transformOrigin: "100% 0%", transition: "500ms" }}
      flexDirection={"row-reverse"}
      ref={ref}
    >
      <Game
        game={finalGame}
        color={"#000"}
        gamesObj={gamesObj}
        onOpenGameDetail={onOpenGameDetail}
      />
      <PrevGame games={prevGames} onOpenGameDetail={onOpenGameDetail} />
    </Box>
  );
});

export default ReverseTree;
