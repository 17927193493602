import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import IconButton, { iconButtonClasses } from "@mui/joy/IconButton";
import Input from "@mui/joy/Input";
import Link from "@mui/joy/Link";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import * as React from "react";
import { LuArrowDown, LuArrowLeft, LuArrowRight, LuFilter, LuSearch } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import useReq from "../../services";
import Dashboard from "../Dashboard";
import Layout from "./../Layout";
import { Checkbox } from "@mui/joy";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function GameFilter() {
  const { state: games, set: getGames, loading } = useReq("get-games");
  const { state: events } = useReq("get-events");
  const [order, setOrder] = React.useState("desc");
  const navigate = useNavigate();
  const handleClick = (id) => {};
  const [open, setOpen] = React.useState(false);
  const [filters, setFilters] = React.useState({});

  const handleChangeFilter = (key, value) => {
    if (value && value !== "*") {
      setFilters({
        ...filters,
        [key]: value,
      });
    } else if (value === "*") {
      const newFilters = { ...filters };
      delete newFilters[key];
      setFilters(newFilters);
    }
  };

  React.useEffect(() => {
    getGames({ ...filters });
  }, [getGames, filters]);

  return (
    <Dashboard title={"فیلتر بازی"}>
      <Layout loading={loading} display={"flex"} flexDirection={"column"} height={"100%"}>
        <Box
          className="SearchAndFilters-tabletUp"
          sx={{
            borderRadius: "sm",
            pb: 2,
            display: {
              xs: "none",
              sm: "flex",
            },
            alignItems: "center",
            flexWrap: "wrap",
            gap: 1.5,
            "& > *": {
              minWidth: {
                xs: "120px",
                md: "160px",
              },
            },
          }}
        >
          <Box sx={{ flex: 1, display: "flex", alignItems: "center", gap: 2 }}>
            <FormControl size="sm">
              <Select
                defaultValue={"*"}
                sx={{ width: 200 }}
                onChange={(_e, v) => handleChangeFilter("event", v)}
                placeholder="رویداد"
              >
                <Option value={"*"}>همه رویداد ها</Option>
                {events.map((e, i) => (
                  <Option key={i} value={e.id}>
                    {e.title}
                  </Option>
                ))}
              </Select>
            </FormControl>
          </Box>

          <FormControl size="sm"></FormControl>
          <Button size="sm" onClick={() => {}}>
            فیلتر
          </Button>
        </Box>
        <Sheet
          className="OrderTableContainer"
          variant="outlined"
          sx={{
            width: "100%",
            borderRadius: "md",
            flex: 1,
            overflow: "auto",
            minHeight: 0,
          }}
        >
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            hoverRow
            sx={{
              "--TableCell-headBackground": (theme) => theme.vars.palette.background.level1,
              "--Table-headerUnderlineThickness": "1px",
              "--TableRow-hoverBackground": (theme) => theme.vars.palette.background.level1,
            }}
          >
            <thead>
              <tr>
                {/* <th style={{ width: 48, textAlign: "center", padding: 12 }}>
                <Checkbox
                  indeterminate={
                    selected.length > 0 && selected.length !== rows.length
                  }
                  checked={selected.length === rows.length}
                  onChange={(event) => {
                    setSelected(
                      event.target.checked ? rows.map((row) => row.id) : []
                    );
                  }}
                  color={
                    selected.length > 0 || selected.length === rows.length
                      ? "primary"
                      : undefined
                  }
                  sx={{ verticalAlign: "text-bottom" }}
                />
              </th> */}
                <th style={{ width: 70, padding: "12px 18px", textAlign: "right" }}>
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    onClick={() => setOrder(order === "asc" ? "desc" : "asc")}
                    fontWeight="lg"
                    endDecorator={<LuArrowDown />}
                    sx={{
                      "& svg": {
                        transition: "0.2s",
                        transform: order === "desc" ? "rotate(0deg)" : "rotate(180deg)",
                      },
                    }}
                  >
                    شناسه
                  </Link>
                </th>
                <th style={{ width: 100, padding: 12, textAlign: "right" }}>بازیکن آبی</th>
                <th style={{ width: 100, padding: 12, textAlign: "right" }}>بازیکن قرمز</th>
                <th style={{ width: 100, padding: 12, textAlign: "right" }}>برنده</th>
                <th style={{ width: 90, padding: 12, textAlign: "right" }}>نتیجه</th>
                <th style={{ width: 90, padding: 12, textAlign: "right" }}>گروه</th>
              </tr>
            </thead>
            <tbody>
              {games &&
                stableSort(games, getComparator(order, "id")).map((row) => (
                  <tr
                    key={row.id}
                    onClick={() => handleClick(row.id)}
                    style={{ cursor: "pointer" }}
                  >
                    <td style={{ textAlign: "right", padding: "0 15px" }}>
                      <Typography fontWeight="md">{row.id}</Typography>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <Typography fontWeight="md">
                        {row.player_one && row.player_one.name}
                      </Typography>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <Typography fontWeight="md">
                        {row.player_two && row.player_two.name}
                      </Typography>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <Typography fontWeight="md">
                        {row.winner === "ONE"
                          ? row.player_one && row.player_one.name
                          : row.winner === "TWO"
                          ? row.player_two && row.player_two.name
                          : ""}
                      </Typography>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <Typography fontWeight="md">
                        {row.score_one}-{row.score_two}
                      </Typography>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <Typography fontWeight="md">
                        {row.group &&
                          `${row.group.belt && row.group.belt.title} (${
                            row.group.weight && row.group.weight.title
                          }) ${row.group.age && row.group.age.title}`}
                      </Typography>
                    </td>
                    {/* <td style={{ textAlign: "right" }}>{georgianToJalaali(row.datetime)}</td> */}
                    {/* <td style={{ textAlign: "right" }}>
                    <Chip
                      variant="soft"
                      size="sm"
                      startDecorator={
                        {
                          DOING: <LuCheck />,
                          FINISHED: <LuCornerUpLeft />,
                          CANCELED: <LuX />,
                        }[row.status]
                      }
                      color={
                        {
                          DOING: "success",
                          FINISHED: "neutral",
                          CANCELED: "danger",
                        }[row.status]
                      }
                    >
                      {
                        {
                          DOING: "درحال انجام بازی",
                          FINISHED: "تمام شده",
                          CANCELED: "لغو شده",
                        }[row.status]
                      }
                    </Chip>
                  </td> */}
                    {/* <td>
                    <Box sx={{ display: "flex", gap: 1, alignItems: "right" }}>
                      <Avatar size="sm">
                        <LuUser size={20} />
                      </Avatar>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {row.coaches && row.coaches.length} نفر
                      </div>
                    </Box>
                  </td> */}
                    {/* <td>
                    <Box sx={{ display: "flex", gap: 1, alignItems: "right" }}>
                      <Avatar size="sm">
                        <TbPlaystationSquare size={20} />
                      </Avatar>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {row.grounds && row.grounds.length} زمین
                      </div>
                    </Box>
                  </td> */}
                    {/* <td style={{ textAlign: "right" }}>
                    <Typography fontWeight="lg" sx={{ display: "inline-block" }} color="neutral">
                      {row.state && row.state.title}
                    </Typography>
                  </td> */}
                    {/* <td style={{ textAlign: "right" }}>
                    <Typography fontWeight="lg" sx={{ display: "inline-block" }} color="neutral">
                      {row.city && row.city.title}
                    </Typography>
                  </td> */}
                  </tr>
                ))}
            </tbody>
          </Table>
        </Sheet>
        {/* <Box
          className="Pagination-mobile"
          sx={{ display: { xs: "flex", md: "none" }, alignItems: "center" }}
        >
          <IconButton aria-label="صفحه قبلی" variant="outlined" color="neutral" size="sm">
            <LuArrowRight />
          </IconButton>
          <Typography level="body2" mx="auto">
            Page 1 of 10
          </Typography>
          <IconButton aria-label="صفحه بعدی" variant="outlined" color="neutral" size="sm">
            <LuArrowRight />
          </IconButton>
        </Box> */}
        {/* <Box
          className="Pagination-laptopUp"
          sx={{
            pt: 4,
            gap: 1,
            [`& .${iconButtonClasses.root}`]: { borderRadius: "50%" },
            display: {
              xs: "none",
              md: "flex",
            },
          }}
        >
          <Button size="sm" variant="plain" color="neutral" startDecorator={<LuArrowRight />}>
            قبلی
          </Button>

          <Box sx={{ flex: 1 }} />
          {["1", "2", "3", "…", "8", "9", "10"].map((page) => (
            <IconButton
              key={page}
              size="sm"
              variant={Number(page) ? "outlined" : "plain"}
              color="neutral"
            >
              {page}
            </IconButton>
          ))}
          <Box sx={{ flex: 1 }} />

          <Button size="sm" variant="plain" color="neutral" endDecorator={<LuArrowLeft />}>
            بعدی
          </Button>
        </Box> */}
      </Layout>
    </Dashboard>
  );
}
