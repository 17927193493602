import { Box, Sheet, Typography } from "@mui/joy";
import React from "react";
import { Link } from "react-router-dom";
import { GroupTitleSM } from "../common/GroupTitle";
import { LuCheckCircle } from "react-icons/lu";
import { RiCheckboxCircleFill } from "react-icons/ri";

const GamesItem = ({ game }) => {
  return (
    <Sheet
      variant="outlined"
      sx={(theme) => ({
        p: 1,
        mt: 1,
        boxShadow: "none",
        borderRadius: theme.radius.sm,
        display: "flex",
        justifyContent: "start",
        gap: 1,
        alignItems: "center",
        ...(game.winner !== "UNK"
          ? { borderLeft: "4px solid var(--joy-palette-primary-200)" }
          : {}),
      })}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          ml: 0.5,
          mr: 1,
          color: "text.tertiary",
        }}
      >
        {game && game.number}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          gap: 0.3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
          }}
        >
          <Typography
            // variant={"soft"}
            startDecorator={game && game.winner === "ONE" ? <RiCheckboxCircleFill /> : null}
            color={"primary"}
          >
            {game && game.player_one && game.player_one.name}
          </Typography>
          <Typography
            // fontSize={"sm"}
            variant="plain"
            level="body3"
            color={"secondary"}
          >
            vs
          </Typography>
          <Typography
            startDecorator={game && game.winner === "TWO" ? <RiCheckboxCircleFill /> : null}
            color={"primary"}
          >
            {game && game.player_two && game.player_two.name}
          </Typography>
        </Box>
        <Link
          style={{ textDecoration: "none" }}
          to={"/dashboard/group-games/" + (game.group && game.group.id)}
        >
          <Box
            display={"flex"}
            py={0.5}
            px={0.5}
            sx={{
              width: "100",
              mr: -0.5,

              mt: 0.5,
              cursor: "pointer",
              "&:hover": { borderRadius: "lg", bgcolor: "primary.100" },
            }}
            alignItems={"center"}
            color={"text.tertiary"}
            gap={0.5}
          >
            <GroupTitleSM group={game.group} />
          </Box>
        </Link>
        {/* {game && `${game.reverse_level} ${game.level} ${game.group} `} */}
      </Box>
    </Sheet>
  );
};

export default GamesItem;
