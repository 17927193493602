import { Box, Button, Input, Typography } from "@mui/joy";
import React, { useState } from "react";
import useReq from "../../services";
import { useLocation, useNavigate } from "react-router-dom";
// import background from "../../../public/images/background.jpg";

const LoginPage = () => {
  const { set: getToken, loading } = useReq("get-token");
  const navigate = useNavigate();
  const [state, setState] = useState({
    username: "",
    password: "",
  });

  const handleGetToken = () => {
    getToken({
      username: state.username,
      password: state.password,
    })
      .then((data) => {
        localStorage.setItem("token", data.access);
        navigate("/dashboard");
      })
      .catch((err) => console.log(err));
  };
  const handleChange = (key) => (e) => {
    setState({ ...state, [key]: e.target.value });
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
        boxShadow: "lg",
      }}
    >
      <div style={{ position: "absolute", inset: 0, overflow: "hidden" }}>
        <img
          style={{ objectFit: "cover", width: "100%", height: "100%" }}
          src={"/images/background.jpg"}
          alt="background"
        />
      </div>
      <Box
        zIndex={1}
        width={"23rem"}
        sx={{ bgcolor: "background.sheet", p: 2, borderRadius: "md" }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", pb: 4, gap: 1 }}>
          <Typography level="h3">تکواندو من</Typography>
          <Typography>ورود به حساب کاربری</Typography>
        </Box>
        <Box sx={{ p: 1, gap: 1.5, py: 2, display: "flex", flexDirection: "column" }}>
          <Input
            placeholder="نام کاربری"
            value={state.username}
            onChange={handleChange("username")}
          />
          <Input
            placeholder="رمز عبور"
            value={state.password}
            type="password"
            onChange={handleChange("password")}
          />
        </Box>
        <Box sx={{ mt: 3, display: "flex", width: "100%", justifyContent: "center" }}>
          <Button loading={loading} onClick={handleGetToken} sx={{ width: "100%" }}>
            تایید
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default LoginPage;
