import axios from "axios";
import React, { useCallback, useState } from "react";
import { ENDPOINTS } from "./urls";
import toast from "react-hot-toast";

const useReq = (endPointId, getInMount = true) => {
  const selectedEndPoint =
    ENDPOINTS.find((ep) => {
      if (ep.id === endPointId) {
        return {
          ...ep,
          ignoreRunInMount: !!ep.ignoreRunInMount,
        };
      }
      return null;
    }) || null;

  const initialLoadingState = selectedEndPoint && !!selectedEndPoint.defaultLoadingState;

  const [state, setState] = useState(null);
  const [loading, setLoading] = useState(initialLoadingState);

  const get = (sep = null, params) => {
    let token = localStorage.getItem("token");

    if (sep) {
      return new Promise((resolve, reject) => {
        setLoading(true);
        axios({
          url:
            typeof sep.url === "function"
              ? sep.url(...(typeof params === "string" ? [params] : params))
              : sep.url,
          method: sep.method,
          headers: {
            Authorization: token && "Bearer " + token,
            ...(sep.isBlob ? { responseType: "blob" } : {}),
            ...(sep.headers ? { ...sep.headers } : {}),
          },
          ...(sep.data ? { data: sep.data } : {}),
        })
          .then((res) => {
            setLoading(false);
            setState(res.data);
            resolve(res.data);
          })
          .catch((err) => {
            reject && reject(err);
            setLoading(false);
          });
      });
    }
  };

  const reGet = useCallback((params = []) => get(selectedEndPoint, params), [selectedEndPoint]);

  const set = useCallback(
    (data, params) => {
      let token = localStorage.getItem("token");
      return new Promise((resolve, reject) => {
        setLoading(true);
        axios({
          headers: {
            Authorization: `Bearer ${token}`,
            ...(selectedEndPoint.isBlob ? { responseType: "blob" } : {}),
            ...(selectedEndPoint.headers ? { ...selectedEndPoint.headers } : {}),
          },
          url:
            typeof selectedEndPoint.url === "function"
              ? selectedEndPoint.url(...(typeof params === "string" ? [params] : params))
              : selectedEndPoint.url,
          method: selectedEndPoint.method,
          data: data,

          ...(selectedEndPoint.isBuffer ? { responseType: "arraybuffer" } : {}),
        })
          .then((res) => {
            console.log(res);
	    setState(res.data);
            setLoading(false);
            resolve(res.data);
          })
          .catch((err) => {
            setLoading(false);
            reject(err);
          });
      });
    },
    [selectedEndPoint]
  );
  React.useEffect(() => {
    if (getInMount && selectedEndPoint && !selectedEndPoint.ignoreRunInMount) {
      get(selectedEndPoint)
        .then((res) => {
          // setState(res);
        })
        .catch(() => {
          toast.error("خطا در دریافت اطلاعات");
        });
    }
  }, [selectedEndPoint, getInMount]);
  return {
    state: state ? state : selectedEndPoint ? selectedEndPoint.default : null,
    get: reGet,
    set: set,
    loading,
    setLoading,
    setState,
  };
};

export default useReq;
