import { Typography } from "@mui/joy";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";
import Sheet from "@mui/joy/Sheet";
import React from "react";
import { LuEdit, LuTrash2 } from "react-icons/lu";

const Table = ({ players, onRemove, onEdit, editedId }) => {
  return (
    <Box sx={{ flex: 1, position: "relative" }}>
      <Sheet
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: "md",
          overflowY: "auto",

          position: "absolute",
          inset: 0,
        }}
      >
        <Box
          sx={{
            position: "sticky",
            top: 0,
            background: "#ffffff",
            zIndex: 10,
          }}
        >
          <Sheet
            sx={{
              display: "grid",
              columnGap: 1.5,
              pb: 0,
              alignItems: "center",
              px: 1.5,
              py: 1,
              borderBottom: "1px solid",
              borderBottomColor: "neutral.100",
              gridTemplateColumns: "4rem auto 15% 10% 15% 15% 4.5rem",
            }}
          >
            <Typography level="body2">شناسه</Typography>
            <Typography level="body2">نام و نام خانوادگی</Typography>
            <Typography level="body2">رده سنی</Typography>
            <Typography level="body2">وزن</Typography>
            <Typography level="body2">کمربند</Typography>
            <Typography level="body2">مربی</Typography>
            <Box></Box>
            <div />
          </Sheet>
        </Box>
        {players.map((p, index) => {
          return (
            <Box
              key={index}
              sx={{
                display: "grid",
                gap: 1.5,
                px: 1.5,
                py: 0.5,
                borderBottom: "1px solid",
                borderBottomColor: "neutral.100",
                gridTemplateColumns: "4rem auto 15% 10% 15% 15% 4.5rem",
                "&:hover": {
                  bgcolor: p.id === editedId ? "primary.200" : "neutral.100",
                },
                ...(p.id === editedId ? { bgcolor: "primary.200" } : {}),
              }}
            >
              <Box sx={{ textAlign: "right", display: "flex", alignItems: "center" }}>{p.id}</Box>
              <Box sx={{ textAlign: "right", display: "flex", alignItems: "center" }}>{p.name}</Box>
              <Box sx={{ textAlign: "right", display: "flex", alignItems: "center" }}>
                {p.age && p.age.title}
              </Box>
              <Box
                sx={{
                  textAlign: "right",
                  direction: "ltr",
                  justifyContent: "end",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span style={{ direction: "ltr" }}>{p.weight && p.weight.title}</span>
              </Box>
              <Box sx={{ textAlign: "right", display: "flex", alignItems: "center" }}>
                {p.belt && p.belt.title}
              </Box>
              <Box>{p.coach && p.coach.name}</Box>
              <Box display={"flex"} alignItems={"center"}>
                <IconButton variant="plain" size="sm" onClick={() => onEdit(p)}>
                  <LuEdit />
                </IconButton>
                <IconButton variant="plain" size="sm" onClick={() => onRemove(p.id)}>
                  <LuTrash2 />
                </IconButton>
              </Box>
            </Box>
          );
        })}
      </Sheet>
    </Box>
  );
};

export default Table;
