import { Box, Chip, Sheet, Typography } from "@mui/joy";
import React from "react";
import { LuArrowLeft } from "react-icons/lu";
import BeltColorCircle from "../../components/common/BeltColorCircle";
import { Link } from "react-router-dom";

const EventAndGroups = ({ count = 0, event }) => {
  return (
    <Sheet
      variant="outlined"
      sx={{
        borderRadius: "md",
        p: 2,
        height: count === 1 ? 1 : null,
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Typography level="body-sm" textColor={"neutral.500"}>
        عنوان مسابقه
      </Typography>
      <Typography level="h3">{event.title}</Typography>
      <Typography level="body-md" textColor={"neutral.700"}>
        {event.description}
      </Typography>
      <Box sx={{ pt: 2, display: "flex", flexWrap: "wrap", gap: 1, alignItems: "flex-end" }}>
        <Typography level="body-md" fontWeight={"bold"} textColor={"neutral.900"}>
          مربیان:
        </Typography>
        {event.coaches && event.coaches.map((c, i) => <Chip key={i}>{c.name}</Chip>)}
      </Box>
      {/* <PlayersButton /> */}
      <Sheet
        variant="outlined"
        sx={{
          borderRadius: "sm",
          overflowY: "auto",
          flex: 1,
          minHeight: { xs: "auto", md: "20rem" },
          mt: 2,
          position: "relative",
        }}
      >
        <Box sx={{ position: { xs: "static", md: "absolute" }, inset: 0 }}>
          <Box
            sx={{
              p: 1,
              position: "sticky",
              top: 0,
              background: "var(--joy-palette-neutral-200)",
            }}
          >
            <Typography>گروه ها</Typography>
          </Box>

          {event.started_groups &&
            event.started_groups.map((sg, index) => (
              <Link to={"/games/" + sg.id} key={index} style={{ textDecoration: "none" }}>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    px: 1,
                    py: 1,
                    cursor: "pointer",
                    ":hover": { background: "var(--joy-palette-primary-200)" },
                    borderBottom: "1px solid var(--joy-palette-neutral-100)",
                  }}
                >
                  <Typography width={"8rem"}>{sg.age.title}</Typography>
                  <Typography width={"3rem"}>{sg.weight.title}</Typography>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <BeltColorCircle style={{ height: "1rem" }} color={sg.belt.color} />
                    <Typography>{sg.belt.title}</Typography>
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "row-reverse",
                      alignItems: "center",
                      gap: 1,
                      color: "neutral.500",
                    }}
                  >
                    <LuArrowLeft />
                    <Typography
                      level="body-sm"
                      sx={{ display: { xs: "none", lg: "block" } }}
                      color="inherit"
                    >
                      مشاهده بازی ها
                    </Typography>
                  </Box>
                </Box>
              </Link>
            ))}
        </Box>
      </Sheet>
    </Sheet>
  );
};

export default EventAndGroups;
