import { Box } from "@mui/joy";
import React from "react";
import EventAndGroups from "../../components/PublicHome/EventAndGroups";
import ImageSide from "../../components/PublicHome/ImageSide";
import PublicHeader from "../../components/shared/PublicHeader";
import PublicLayout from "../../components/shared/PublicLayout";
import useReq from "../../services";
import Toast from "../../components/Toast";

const PublicHomePage = () => {
  const { state: events } = useReq("get-available-events");
  return (
    <PublicLayout>
      <PublicHeader />
      <Box sx={{ display: "flex", flexDirection: "row", width: "100%", flex: 1 }}>
        <Box sx={{ flex: 1, position: "relative" }}>
          <Box
            sx={{
              position: { xs: "static", md: "absolute" },
              inset: 0,
              p: 2,
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            {events &&
              events.map((event, index) => (
                <EventAndGroups event={event} key={index} count={events.length} />
              ))}
          </Box>
        </Box>
        <ImageSide />
      </Box>
      <Toast />
    </PublicLayout>
  );
};

export default PublicHomePage;
